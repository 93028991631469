import React, { Component, Fragment } from 'react';
import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';
import { withAuth0 } from '@auth0/auth0-react';
import moment from 'moment';

import * as actions from './store/actions';
import './assets/omz-vish';
import './assets/inov8-sdk';
import './assets/ttv-sdk';
import {
	mapNewMessageItem,
	mapNewInteractionItem,
	initNotification,
	getMainIdentifierFromInteraction,
	killSession,
	isZenviaDomain,
	isAuth0Enabled,
	isZenviaEmail,
	decodeJWT
} from './shared/utility';
import { ACTIVE_INTERACTION_STATES, ZENVIA_FAVICON } from './shared/consts';
import Page from './components/Pages';
import Text from './components/Atoms/Text';
import InitialLoader from './components/Molecules/InitialLoader';
import Alert from './components/Atoms/Alert';
import Backdrop from './components/Molecules/Backdrop';
import BlockedAccess from './components/Organism/BlockedAccess';
import Button from './components/Molecules/Buttons/Button';
import ErrorBoundary from './hoc/ErrorBoundary';
import Login from './components/Organism/Login';
import ReceptiveCallModal from './components/Molecules/ReceptiveCall';
import ActiveInteractionsModal from './components/Molecules/ActiveInteractionsModal';
import AgentHasNoActiveDepartmentModal from './components/Molecules/AgentHasNoActiveDepartmentModal';
import AddCustomer from './components/Organism/AddCustomer';
import EditCustomer from './components/Organism/EditCustomer';
import InternalMessages from './components/Molecules/InternalMessages';
import TaskNotification from './components/Molecules/TaskNotification';
import Notifications from './components/Molecules/Notifications';
import HasAnotherSession from './components/Molecules/HasAnotherSession';
import BreakStatusTimer from './components/Molecules/BreakStatusTimer';

if (process.env.NODE_ENV !== 'development') {
	Sentry.init({
		dsn: 'https://203481f98fc4ace782d36a7ca01c56e0@o127344.ingest.us.sentry.io/4507056988291072',
		normalizeDepth: 4,
		ignoreErrors: ['ResizeObserver loop limit exceeded', 'ResizeObserver loop completed with undelivered notifications']
	});
}

const messages = defineMessages({
	by: {
		id: 'app.by',
		defaultMessage: 'De'
	},
	image: {
		id: 'app.image',
		defaultMessage: 'Imagem'
	},
	video: {
		id: 'app.video',
		defaultMessage: 'Video'
	},
	attachment: {
		id: 'app.attachment',
		defaultMessage: 'Arquivo'
	},
	receivingCall: {
		id: 'app.receivingCall',
		defaultMessage: 'Recebendo chamada'
	},
	createdInteraction: {
		id: 'createdInteraction',
		defaultMessage: 'Interação criada'
	},
	createdInteractionContent: {
		id: 'createdInteractionContent',
		defaultMessage: 'A interação foi criada com sucesso'
	},
	useHere: {
		id: 'app.useHere',
		defaultMessage: 'Usar aqui'
	},
	useHereDescription: {
		id: 'app.useHereDescription',
		defaultMessage: 'O usuário já possui uma sessão ativa, finalize as outras sessões e atualize a página para prosseguir.'
	},
	killSession: {
		id: 'app.killSession',
		defaultMessage: 'Conta acessada em outro local'
	},
	killSessionContent: {
		id: 'app.killSessionContent',
		defaultMessage: 'Não são permitidos acessos simultâneos'
	},
	inactiveSession: {
		id: 'app.inactiveSession',
		defaultMessage: 'Sessão inativa'
	},
	inactiveSessionContent: {
		id: 'app.inactiveSessionContent',
		defaultMessage: 'Sua sessão estava inativa e foi recuperada automaticamente.'
	},
	tagsRequired: {
		id: 'app.tagsRequired',
		defaultMessage: 'Para fechar a interação, adicione as tags necessárias à interação'
	},
	sessionExpired: {
		id: 'app.sessionExpired',
		defaultMessage: 'Sessão expirada'
	},
	invalidToken: {
		id: 'app.invalidToken',
		defaultMessage: 'Realize login novamente'
	},
	acceptInteractionFailed: {
		id: 'app.acceptInteractionFailed',
		defaultMessage: 'Não foi possível confirmar o atendimento'
	},
	acceptInteractionFailedContent: {
		id: 'app.acceptInteractionFailedContent',
		defaultMessage: 'Atendido por outro atendente'
	},
	ok: {
		id: 'app.ok',
		defaultMessage: 'OK'
	},
	transferredInteractionTitle: {
		id: 'app.notification.transferredInteractionTitle',
		defaultMessage: 'Atendimento transferido'
	},
	transferredInteractionToAgent: {
		id: 'app.notification.transferredInteractionToAgent',
		defaultMessage: 'Seu atendimento ({protocol}) foi transferido para o agente: {agent}'
	},
	transferredInteractionToDepartment: {
		id: 'app.notification.transferredInteractionToDepartment',
		defaultMessage: 'Seu atendimento ({protocol}) foi transferido para o departamento: {department}'
	},
	changedStatusTitle: {
		id: 'app.notification.changedStatusTitle',
		defaultMessage: 'Status alterado'
	},
	changedStatusContent: {
		id: 'app.notification.changedStatusContent',
		defaultMessage: 'O administrador {changedBy} alterou seu status para {newStatus}'
	},
	blockInteractionsOnBreakTitle: {
		id: 'app.blockInteractionsOnBreak.title',
		defaultMessage: 'Em status de pausa'
	},
	blockInteractionsOnBreakInfo: {
		id: 'app.blockInteractionsOnBreak.info',
		defaultMessage: 'Para utilizar a plataforma, você precisa encerrar a pausa!'
	},
	blockInteractionsOnBreakButton: {
		id: 'app.blockInteractionsOnBreak.button',
		defaultMessage: 'Sair da pausa'
	},
	per: {
		id: 'messageState.by',
		defaultMessage: 'por'
	}
});

class App extends Component {
	constructor() {
		super();
		this.state = {
			startedOmzVish: false,
			notify: {
				show: false,
				type: '',
				content: '',
				showBrowserNotification: false
			},
			startedTaskInterval: false,
			isAuth0SessionKilled: false
		};
	}

	componentDidMount() {
		const {
			logIn,
			verifyToken,
			onNewInteraction,
			onAccepted,
			onUpdateInteraction,
			onNewMessage,
			onReceivedMessage,
			onNewWhatsappVoiceCall,
			onWhatsappCallDuration,
			onDeliveredMessage,
			onTyping,
			onCleared,
			onTransferred,
			onFinished,
			updateAgentBreakStatus,
			fetchInteractionsCount,
			fetchMissedInteractions,
			removeInteraction,
			onUnauthorized,
			fetchTaskCount,
			onUpdateCallStatus,
			onHideReceptiveModal,
			setOnlyChatOrPhone,
			setInteractionList,
			onShowCall,
			onFinish,
			onFinishTask,
			onFinishInteractionTask,
			addNotification,
			intl,
			auth0,
			loadInteractionInfo,
			updateUserID
		} = this.props;
		const {
			startedTaskInterval
		} = this.state;

		this.checkIdentity();

		const sessionToken = sessionStorage.getItem('omz_token');
		const { formatMessage } = intl;

		if (isAuth0Enabled() && isZenviaDomain()) {
			if (auth0.isAuthenticated) {
				const { agent } = this.props;

				auth0.getAccessTokenSilently().then((tokenAuth) => {
					const decodedToken = decodeJWT(tokenAuth);
					updateUserID(decodedToken.sub.replace(/\|/g, '%7C'));
				});

				if (window.localStorage.getItem('kill')) {
					// something told us to disconnect
					this.setState({ isAuth0SessionKilled: true });
					window.sessionStorage.clear();
					window.localStorage.removeItem('kill');
				} else if (!sessionToken && !agent.error) {
					// If we are logged in Auth0 but doesn't have a ZChat Token, we must ask for one
					// Wait 1 sec to avoid socket errors.
					setTimeout(() => logIn(auth0, setOnlyChatOrPhone), 1000);
				} else if (agent.loading && !agent.error) {
					// If already logged into SSO *and* has a ZChat Token, we just need to verify it
					verifyToken(sessionToken, setOnlyChatOrPhone);
				}
			}
		} else if (sessionToken) {
			// For WhiteLabel accounts - we use the old method
			verifyToken(sessionToken, setOnlyChatOrPhone, sessionStorage.getItem('login'));
		}

		window.omzVish.on('unauthorized', () => {
			onUnauthorized();
		});

		window.omzVish.on('newInteraction', (interaction) => {
			const { defineSelectedInteraction, interactions, agent } = this.props;
			const { info, isAutoAcceptInteraction, isFullAutomatic } = agent;
			const {
				interactionType,
				interactionHash,
				currentState,
				updatedAt = new Date()
			} = interaction;
			const shouldAutoAcceptNotify = (isAutoAcceptInteraction || isFullAutomatic) && moment().diff(moment(updatedAt), 'seconds') < 5;
			const existActiveInteraction = interactions.some(activeInteraction => (
				ACTIVE_INTERACTION_STATES.includes(activeInteraction.currentState)
				&& activeInteraction.interactionHash === interactionHash
			));

			if (window.ttvSdk.connected && interactionType.includes('PHONE') && !['MISSED', 'REPLYING'].includes(currentState)) {
				window.ttvSdk.currentInteraction = interactionHash;
				defineSelectedInteraction(interactionHash);
				if (window.ttvSdk.chamadaId) this.showReceivedModalEvents(window.ttvSdk.numeroChegando);
			} else if (['RINGING'].includes(currentState)) {
				this.setState({
					notify: {
						show: true,
						type: 'new_interaction',
						content: `${formatMessage(messages.by)}: ${getMainIdentifierFromInteraction(interaction)}`,
						showBrowserNotification: true
					}
				});
			}

			if (existActiveInteraction) loadInteractionInfo(info.account.id, interactionHash);

			if (currentState === 'TALKING' && shouldAutoAcceptNotify) {
				this.setState({
					notify: {
						show: true,
						type: 'autoAcceptInteraction',
						content: `${formatMessage(messages.by)}: ${getMainIdentifierFromInteraction(interaction)}`,
						showBrowserNotification: true
					}
				});
			}

			onNewInteraction(mapNewInteractionItem(interaction));
		});

		window.omzVish.on('acceptedInteraction', (response, status) => {
			if (response.constructor === Object) {
				const { interactionHash, message, type } = response;

				if (type === 'acceptFailed' && message === 'Accepted by other agent') {
					addNotification({
						automaticClose: false,
						content: formatMessage(messages.acceptInteractionFailedContent),
						title: formatMessage(messages.acceptInteractionFailed),
						type: 'warning'
					});
				}
				onAccepted(interactionHash, status);
			} else {
				onAccepted(response, status);
			}
		});

		window.omzVish.on('breakStatusChanged', ({ action, breakStatus, changedBy }) => {
			const { registered, unRegistered } = this.props;
			const params = {
				action,
				breakStatus: breakStatus || {},
				shouldChangeBreakStatusInBackend: false
			};

			if (action === 'PAUSE') unRegistered(); else registered();

			updateAgentBreakStatus(params);
			addNotification({
				title: formatMessage(messages.changedStatusTitle),
				content: formatMessage(messages.changedStatusContent, { changedBy, newStatus: breakStatus ? breakStatus.status : 'Online' }),
				automaticClose: false,
				type: 'warning'
			});
		});

		window.omzVish.on('finishedAllMissed', () => {
			fetchMissedInteractions(1, true);
			fetchInteractionsCount();
		});

		window.omzVish.on('updateInteraction', (interaction) => {
			const { agent, interactions, pendingInteractions } = this.props;
			const { isFullAutomatic } = agent;
			const allInteractions = interactions.concat(pendingInteractions);

			onUpdateInteraction(mapNewInteractionItem(interaction), agent);

			if (isFullAutomatic) {
				const { interactionHash, currentState } = interaction;
				const selectedInteraction = allInteractions.find(
					int => int.interactionHash === interactionHash
					&& int.currentState !== currentState
					&& currentState !== 'ENDED'
				);

				if (selectedInteraction) {
					const newInteractionList = ACTIVE_INTERACTION_STATES.includes(currentState) ? 'talking' : 'inbox';
					setInteractionList(newInteractionList);
				}
			}

			if (interaction.cause && ['transferToAgent', 'transferToDepartment'].includes(interaction.cause)) {
				const title = {
					transferToAgent: formatMessage(messages.transferredInteractionTitle),
					transferToDepartment: formatMessage(messages.transferredInteractionTitle)
				};
				const content = {
					transferToAgent: formatMessage(messages.transferredInteractionToAgent, { protocol: interaction.id, agent: interaction.destination }),
					transferToDepartment: formatMessage(messages.transferredInteractionToDepartment, { protocol: interaction.id, department: interaction.destination })
				};

				addNotification({
					title: title[interaction.cause],
					content: content[interaction.cause],
					automaticClose: false,
					type: 'warning'
				});
			}
		});

		window.omzVish.on('newMessage', (newMessage) => {
			const { interactionHash } = newMessage;
			const { content, contentType, source } = newMessage;
			onNewMessage(mapNewMessageItem({ ...newMessage, origin: source }), interactionHash);

			let notifyContent = content;
			if (contentType && contentType.includes('image')) notifyContent = formatMessage(messages.image);
			if (contentType && contentType.includes('video')) notifyContent = formatMessage(messages.video);
			if (contentType && contentType.includes('file')) notifyContent = formatMessage(messages.attachment);

			this.setState({
				notify: {
					show: true,
					type: 'newMessage',
					content: notifyContent,
					showBrowserNotification: true
				}
			});
		});

		window.omzVish.on('newWhatsappVoiceCall', (newCall) => {
			const {
				defineTemplate,
				defineSelectedInteraction,
				defineMainboxSection
			} = this.props;
			onNewWhatsappVoiceCall(newCall);

			setTimeout(() => {
				defineTemplate('default');
				defineSelectedInteraction(newCall.interactionHash);
				defineMainboxSection('default');
			}, [1000]);
		});

		window.omzVish.on('terminateWhatsappVoiceCall', () => {
			onNewWhatsappVoiceCall({});
			onWhatsappCallDuration({});
		});

		window.omzVish.on('receivedMessage', (receivedMessage) => {
			onReceivedMessage(receivedMessage);
		});

		window.omzVish.on('deliveredMessage', (deliveredMessage) => {
			onDeliveredMessage(deliveredMessage);
		});

		window.omzVish.on('typing', (interactionHash) => {
			onTyping(interactionHash);
		});

		window.omzVish.on('cleared', (interactionHash) => {
			onCleared(interactionHash);
		});

		window.omzVish.on('transferred', (interactionHash) => {
			onTransferred(interactionHash);
		});

		window.omzVish.on('finishedInteraction', (interactionHash, expired = false) => {
			onFinished({ interactionHash, expired });
		});

		window.omzVish.on('newCustomer', newCustomer => newCustomer);

		window.omzVish.on('removeCustomer', newCustomer => newCustomer);

		window.omzVish.on('canceled', (interactionHash) => {
			removeInteraction({ interactionHash });
		});

		window.omzVish.on('kill', ({ type }) => {
			const hasAuth0 = isAuth0Enabled() && isZenviaDomain();
			this.setState({ isAuth0SessionKilled: hasAuth0 });
			killSession(type, false, false, hasAuth0);
		});

		window.omzVish.on('createdPhoneInteraction', (interaction) => {
			const {
				defineSelectedInteraction,
				defineMainboxSection,
				updateCreatingInteraction,
				defineTemplate
			} = this.props;

			const { interactionHash } = interaction;

			if (window.ttvSdk.connected) {
				window.ttvSdk.currentInteraction = interactionHash;
				if (window.ttvSdk.conferenceId) window.ttvSdk.answer();
			}

			window.Inov8Sdk.createdInteraction = interactionHash;
			defineSelectedInteraction(interactionHash);
			defineMainboxSection('default');
			onNewInteraction(mapNewInteractionItem(interaction));
			updateCreatingInteraction({});
			this.notifyCreatedInteraction();
			defineTemplate('default');
		});

		window.omzVish.on('updateTasks', () => fetchTaskCount());

		window.omzVish.on('createdInteraction', (interaction) => {
			const {
				defineSelectedInteraction,
				defineMainboxSection,
				updateCreatingInteraction,
				defineTemplate
			} = this.props;

			const { interactionHash } = interaction;

			defineSelectedInteraction(interactionHash);
			defineMainboxSection('default');
			onNewInteraction(mapNewInteractionItem(interaction));
			updateCreatingInteraction({});
			this.notifyCreatedInteraction();
			defineTemplate('default');
		});

		window.omzVish.on('createdInteractionFromMissed', (interaction) => {
			const {
				defineSelectedInteraction,
				defineMainboxSection,
				updateCreatingInteraction,
				defineTemplate
			} = this.props;

			const { missedInteractionHash, interactionHash } = interaction;
			defineSelectedInteraction(interactionHash);
			defineMainboxSection('default');
			onNewInteraction(mapNewInteractionItem(interaction));
			updateCreatingInteraction({});
			this.notifyCreatedInteraction();
			defineTemplate('default');
			onFinished({ interactionHash: missedInteractionHash, removeThisInteraction: true });
		});

		window.Inov8Sdk.on('acceptedCall', (callId, number) => {
			window.omzVish.newPhoneInteraction(number, callId, true);
		});

		window.Inov8Sdk.on('finishedInteraction', (interactionHash) => {
			window.omzVish.finishInteraction(interactionHash);
		});

		window.ttvSdk.on('acceptedCall', (number, callId) => {
			const { creatingInteraction, updateCreatingInteraction } = this.props;
			const { taskId, finishInteraction } = creatingInteraction;
			if (finishInteraction) {
				onFinish({
					interactionHash: finishInteraction,
					removeThisInteraction: true
				});
				updateCreatingInteraction({});
			}
			window.omzVish.newPhoneInteraction(number, callId, true, taskId);
		});

		window.ttvSdk.on('status', (status) => {
			const { updateCreatingInteraction } = this.props;
			if (status === 'conversando') window.ttvSdk.onTransfer = false;
			if (status === 'conversando' || status === 'encerrada') {
				this.setState({
					notify: {
						show: false,
						type: '',
						content: '',
						showBrowserNotification: true
					}
				});
			}

			if (status === 'encerrada') {
				updateCreatingInteraction({});
				onHideReceptiveModal();
			}

			onUpdateCallStatus(status);
		});

		window.ttvSdk.on('error', (status) => {
			const { agent } = this.props;
			const { phoneData } = agent;
			if (status === 'Erro de conexão') {
				window.ttvSdk.connect(phoneData.token, phoneData.station);
			}
		});

		window.ttvSdk.on('newCall', (data) => {
			const {
				agent,
				onChangeInputPhone,
				creatingInteraction,
				updateCreatingInteraction
			} = this.props;
			const { chamadaRecebidaId, numeroChegando } = data;
			window.ttvSdk.chamadaId = chamadaRecebidaId;
			window.ttvSdk.numeroChegando = numeroChegando;

			if (data.message === 'chegandoChamada' && chamadaRecebidaId !== window.ttvSdk.refusedId) {
				const clientNumber = data.numeroChegando && data.numeroChegando.replace(/\D/g, '');
				const agentNumber = data.numeroDestino && data.numeroDestino.replace(/\D/g, '');
				if (parseInt(agentNumber, 10) === agent.info.totalVoice.station) {
					if (window.ttvSdk.conferenceId) {
						onUpdateCallStatus('conectado');
						const { finishInteraction, info } = creatingInteraction;
						const conferenceId = window.ttvSdk.conferenceId.toString();
						window.omzVish.newConferencePhoneInteraction(info, conferenceId);
						if (finishInteraction) {
							onFinish({
								interactionHash: finishInteraction,
								removeThisInteraction: true
							});
							updateCreatingInteraction({});
						}
					} else {
						window.omzVish.transferPhoneInteraction(clientNumber, chamadaRecebidaId);
					}
				} else {
					window.omzVish.newPhoneInteraction(clientNumber, chamadaRecebidaId, false);
				}
			}

			if (chamadaRecebidaId === window.ttvSdk.refusedId) {
				window.ttvSdk.hangup();
				onChangeInputPhone('');
			}
		});

		window.omzVish.on('receivedOffer', (data) => {
			const { interactionHash } = data;
			onShowCall(interactionHash);
			const self = window.omzVish;
			self.startVideoConnection(interactionHash, true, () => {
				self.connectedUser = data.name;
				self.myConnection.setRemoteDescription(new RTCSessionDescription(data.offer));

				self.myConnection.createAnswer().then((answer) => {
					self.myConnection.setLocalDescription(answer);
					const msg = {
						answer,
						interactionHash,
						accountId: self.accountId
					};

					self.channel.push('answer', msg);
				}).catch(() => { });
			});
		});

		window.omzVish.on('finishTask', ({ taskId }) => {
			onFinishInteractionTask(taskId);
			onFinishTask(taskId);
		});

		if (localStorage.getItem('kill') === 'true') {
			addNotification({
				title: formatMessage(messages.killSession),
				content: formatMessage(messages.killSessionContent),
				type: 'error',
				automaticClose: false
			});
			localStorage.removeItem('kill');
		} else if (localStorage.getItem('sessionRecoverNotification') === 'true') {
			addNotification({
				title: formatMessage(messages.inactiveSession),
				content: formatMessage(messages.inactiveSessionContent),
				type: 'warning',
				automaticClose: false
			});
			localStorage.removeItem('sessionRecoverNotification');
		}

		if (localStorage.getItem('remindLater') && !startedTaskInterval) {
			this.setState({ startedTaskInterval: true });
			this.starTaskInterval();
		}

		if (localStorage.getItem('invalidToken') === 'true') {
			addNotification({
				title: formatMessage(messages.sessionExpired),
				content: formatMessage(messages.invalidToken),
				type: 'error',
				automaticClose: false
			});
			localStorage.removeItem('invalidToken');
		}
	}

	componentDidUpdate() {
		const {
			auth0,
			agent,
			activePhoneInterface,
			definePhoneConnectionData,
			updateCallDuration,
			whatsappCallDuration
		} = this.props;
		const { startedOmzVish, startedTaskInterval } = this.state;

		this.checkAuth();

		if (!agent.loading && Object.keys(agent.info).length > 0 && !startedOmzVish) {
			this.addOmzVishEvents();
			this.connectOmzVish();

			const { totalVoice = {} } = agent.info;
			const { station, token } = totalVoice;
			const {
				account,
				email,
				name,
				profile,
				chameleonHash,
				date_creation: created,
				licenseCode: license,
				id: userId
			} = agent.info;

			window.chmln.identify(email, {
				system: 'zchat-agent',
				userId,
				name,
				email,
				license,
				profile,
				created,
				uid_hash: chameleonHash,
				company: {
					id: account.id,
					uid: account.organizationId
				}
			});

			if (station && token) {
				activePhoneInterface();
				definePhoneConnectionData(({
					name: 'totalVoice',
					station,
					token
				}));
			}

			if (isAuth0Enabled() && isZenviaDomain() && !isZenviaEmail(email)) {
				auth0.getAccessTokenSilently().then((tokenAuth) => {
					const decodedToken = decodeJWT(tokenAuth);
					this.startFullStory(decodedToken.sub, agent.info);
				});
			} else {
				window.FS.shutdown();
			}
		}

		if (localStorage.getItem('remindLater') && !startedTaskInterval) {
			this.setState({ startedTaskInterval: true });
			this.starTaskInterval();
		}

		if (whatsappCallDuration.interactionHash && !this.intervalId) {
			this.callDuration = 0;
			this.intervalId = setInterval(() => {
				this.callDuration += 1;
				updateCallDuration(this.callDuration);
			}, 1000);
		}

		if (!whatsappCallDuration.interactionHash && this.intervalId) {
			clearInterval(this.intervalId);
			this.intervalId = null;
		}
	}

	starTaskInterval = () => {
		const { onInformExpiringSoonTaks } = this.props;
		this.interval = setInterval(() => {
			const pastTime = new Date(localStorage.getItem('remindLater'));
			const currentTime = new Date();
			const diff = Math.abs(currentTime.getTime() - pastTime.getTime());
			const diffMinutes = Math.floor((diff / 1000) / 60);
			if (diffMinutes >= 5) {
				onInformExpiringSoonTaks(true, true);
				localStorage.removeItem('remindLater');
				this.setState({ startedTaskInterval: false });
				clearInterval(this.interval);
			}
		}, 1000);
	}

	checkAuth = () => {
		const { agent } = this.props;
		const { authenticated } = agent;
		if (authenticated) window.location.reload();
	}

	checkIdentity = () => {
		const { fetchIdentity, defineDefaultIdentity } = this.props;
		const location = window.location.hostname;

		if (['localhost', 'homolog', 'zenvia'].some(domain => location.includes(domain))) {
			document.title = 'Zenvia';
			document.getElementById('favicon').href = ZENVIA_FAVICON;
			defineDefaultIdentity();
		} else {
			fetchIdentity(location);
		}
	}

	connectOmzVish = () => {
		const { agent } = this.props;

		window.omzVish.connect(agent.info, false);

		this.setState({
			startedOmzVish: true
		});
	}

	addOmzVishEvents = () => {
		window.omzVish.on('registered', () => {
			const { registered, sentMessages, onSendMessage } = this.props;

			if (sentMessages.length > 0) {
				sentMessages.forEach((sentMessage) => {
					const { message, interactionHash } = sentMessage;
					if (message.contentType === 'text') {
						window.omzVish.newMessage(message.content, interactionHash, 'text');
						onSendMessage(message, interactionHash);
					}
				});
			}

			registered();
		});

		window.omzVish.on('unregistered', () => {
			const { unRegistered } = this.props;

			unRegistered();
		});
	}

	clearOtherConnections = () => {
		const { agent } = this.props;
		const { info } = agent;
		window.omzVish.connect(info, true);
		setTimeout(() => {
			window.location.reload();
		}, 2000);
	}

	logout = () => {
		const { auth0 } = this.props;
		window.FS.anonymize();
		window.omzVish.logout();
		window.sessionStorage.clear();
		window.location.reload();
		window.localStorage.removeItem('lastAgentStatus');
		window.localStorage.removeItem('showOnlyActiveInteractions');
		auth0.logout({ returnTo: window.location.origin });
	}

	redirectToLogin = () => {
		const { auth0 } = this.props;
		window.sessionStorage.clear();
		window.location.reload();
		auth0.logout({ returnTo: window.location.origin });
	}

	onCancelNotify = () => {
		const { notify } = this.state;
		if (notify.show) {
			this.setState({
				notify: {
					show: false,
					type: '',
					content: '',
					showBrowserNotification: true
				}
			});
		}
	}

	onCancelBrowserNotify = () => {
		const { notify } = this.state;
		if (notify.showBrowserNotification) {
			this.setState({
				notify: {
					...notify,
					showBrowserNotification: false
				}
			});
		}
	}

	showReceivedModalEvents = (comingNumber) => {
		const { onUpdateCallStatus, onShowReceptiveModal, intl } = this.props;
		const { formatMessage } = intl;

		onUpdateCallStatus('conectado');
		onShowReceptiveModal();

		this.setState({
			notify: {
				show: true,
				type: 'new_interaction',
				content: `${formatMessage(messages.receivingCall)} ${comingNumber}`,
				showBrowserNotification: true
			}
		});
	}

	closeAddCustomer = () => {
		const { addCustomerClear, hideAddCustomer } = this.props;
		addCustomerClear();
		hideAddCustomer();
	}

	closeEditCustomer = () => {
		const { hideEditCustomer } = this.props;
		hideEditCustomer();
	}

	notifyCreatedInteraction = () => {
		const { intl, addNotification } = this.props;
		const { formatMessage } = intl;

		addNotification({
			title: formatMessage(messages.createdInteraction),
			content: formatMessage(messages.createdInteractionContent),
			type: 'success'
		});
	}

	startFullStory = (userId, agentInfo) => {
		try {
			const locale = localStorage.getItem('locale') || 'pt';
			if (!userId) throw new Error('UserId not found');

			const fullStoryData = {
				displayName: agentInfo.name,
				email: agentInfo.email,
				organization: {
					id_str: agentInfo.account.organizationId
				},
				app_str: 'Zenviachat',
				app: {
					zenviachat: {
						agentId_int: agentInfo.id,
						accountId_int: agentInfo.account.id,
						accountName_str: agentInfo.account.name,
						licenseCode_str: agentInfo.licenseCode,
						partnerId_int: agentInfo.account.partner_id,
						profile_str: agentInfo.profile,
						station_int: agentInfo.totalVoice.station,
						createdAt_date: agentInfo.date_creation,
						language_str: locale
					}
				}
			};

			window.FS.identify(userId, fullStoryData);
		} catch (e) {
			window.FS.log('error', `FullStory was not able to start: ${e.meassage}`);
			window.FS.shutdown();
		}
	}

	onClearBreakStatus = () => {
		const { updateAgentBreakStatus } = this.props;

		const params = {
			breakStatus: {},
			action: 'UNPAUSE'
		};

		updateAgentBreakStatus(params);
	};

	render() {
		const {
			agent,
			shouldNotify,
			intl,
			defaultIdentity,
			identity,
			template,
			showReceptiveModal,
			onHideReceptiveModal,
			currentInteractionHash,
			creatingInteraction,
			updateCreatingInteraction,
			showingAddCustomer,
			showingEditCustomer,
			showingInternalMessages,
			updateInteractionStatus,
			informExpiringSoon,
			notifications,
			tokenExpired
		} = this.props;
		const blockInteractionsOnBreak = agent.agentBreakStatus.preBreak === false && agent.breakStatus.blockInteractionsOnBreak;
		const { notify, isAuth0SessionKilled } = this.state;
		const { unauthorized } = agent;
		const { formatMessage } = intl;
		const token = sessionStorage.getItem('omz_token');
		initNotification();

		/* eslint-disable-next-line camelcase */
		const { login_color } = identity;
		/* eslint-disable-next-line camelcase */
		const renamedLoginColor = login_color;
		const scriptStyles = (
			<style>
				{`:root {
					--color-blue: #0096dc;
					--color-blue-1: #00ebff;
					--color-light-blue: #0096dc1a;
					--color-green: #7EB23C;
					--color-light-green: #e2f0d1;
					--color-dark-green: #175f19;
					--color-orange: #eb6400;
					--color-light-orange: #fbe0cc;
					--color-yellow: #ff9720;
					--color-red: #b72126;
					--color-light-red: #f1d3d4;
					--color-white: #ffffff;
					--color-black: #000000;
					--color-light: #F8FAFC;
					--color-light-grey: #E2E8F0;
					--color-light-grey-1: #f7f7f7;
					--color-light-grey-2: #b3c7dd;
					--color-light-grey-3: #d8d8d8;
					--color-grey: #cad5e3;
					--color-dark-grey: #706c6d;
					--color-dark-grey-1: #94A3B8;
					--color-dark-grey-2: #6d6d6d;
					--color-dark-grey-3: #5c6064;
					--color-dark-grey-4: #475569;
					--color-dark: #0F172A;
					--backdropColor: rgba(10, 10, 10, 0.6);
					--shadow: 0 16px 72px rgba(0, 0, 0, 0.12);
					--shadow-notification: 0 0 2rem rgba(0, 0, 0, 0.08);
					--shadow-light: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
					--color-primary: ${defaultIdentity ? '#2c4fd8' : renamedLoginColor || '#fff'}
				`}
			</style>
		);

		const notificationSection = <Notifications notifications={notifications} />;
		const accessDenied = sessionStorage.getItem('accessDenied') === 'true';

		if (accessDenied) {
			return <BlockedAccess redirectToLogin={this.redirectToLogin} />;
		}
		if (isAuth0SessionKilled) {
			return (
				<Fragment>
					{scriptStyles}
					<HasAnotherSession />
				</Fragment>
			);
		}

		if (!token) {
			return (
				<Fragment>
					{notificationSection}
					<Login />
					{scriptStyles}
				</Fragment>
			);
		}

		if (agent.loading) {
			return (
				<Fragment>
					{scriptStyles}
					<InitialLoader />
				</Fragment>
			);
		}

		return (
			<ErrorBoundary>
				{notificationSection}
				{showReceptiveModal && (
					<ReceptiveCallModal
						closeReceptiveModal={onHideReceptiveModal}
						interactionHash={currentInteractionHash}
						updateInteractionStatus={updateInteractionStatus}
					/>
				)}
				{'hasActiveDepartment' in agent.info && !agent.info.hasActiveDepartment && (
					<AgentHasNoActiveDepartmentModal
						close={this.logout}
						hasBlur
					/>
				)}
				{showingAddCustomer && <AddCustomer onClose={this.closeAddCustomer} />}
				{showingEditCustomer && <EditCustomer onClose={this.closeEditCustomer} />}
				{showingInternalMessages && <InternalMessages />}
				{blockInteractionsOnBreak && (
					<Backdrop higher hasBlur>
						<div className="blockOnBreakStatus">
							<Text size="large">{formatMessage(messages.blockInteractionsOnBreakTitle)}</Text>
							<div className="blockOnBreakStatus--body">
								<span>{formatMessage(messages.blockInteractionsOnBreakInfo)}</span>

								<div className="blockOnBreakStatus--info">
									<span>{agent.agentBreakStatus.emoji}</span>
									<span>{agent.agentBreakStatus.status}</span>
									<span>{formatMessage(messages.per)}</span>
									<BreakStatusTimer className="color-red" />
								</div>
							</div>
							<Button click={() => this.onClearBreakStatus()}>
								{formatMessage(messages.blockInteractionsOnBreakButton)}
							</Button>
						</div>
					</Backdrop>
				)}
				{unauthorized && (
					<Backdrop unauthorized>
						<div className="UnauthorizedInfo">
							{formatMessage(messages.useHereDescription)}
							<Button
								click={this.clearOtherConnections}
								active
								selected
							>
								{formatMessage(messages.useHere)}
							</Button>
						</div>
					</Backdrop>
				)}
				{tokenExpired && (
					<Backdrop higher>
						<div className="tokenExpiredInfo">
							<div id="infos">
								<Text size="large">{formatMessage(messages.sessionExpired)}</Text>
								<Text size="big">{formatMessage(messages.invalidToken)}</Text>
							</div>
							<Button
								click={this.logout}
								active
								selected
							>
								{formatMessage(messages.ok)}
							</Button>
						</div>
					</Backdrop>
				)}
				{informExpiringSoon && <TaskNotification />}
				{Object.keys(creatingInteraction).length > 0 && (
					<ActiveInteractionsModal
						activeInfo={creatingInteraction}
						updateCreatingInteraction={updateCreatingInteraction}
					/>
				)}
				<Alert
					type={notify.type}
					content={notify.content}
					notify={notify.show && shouldNotify}
					onCancelNotify={this.onCancelNotify}
					onCancelBrowserNotify={this.onCancelBrowserNotify}
					showBrowserNotification={notify.showBrowserNotification}
				/>
				<Page template={template} />
				{scriptStyles}
			</ErrorBoundary>
		);
	}
}

App.propTypes = {
	auth0: PropTypes.shape({
		user: PropTypes.shape({
			name: PropTypes.string,
			email: PropTypes.string
		}),
		isAuthenticated: PropTypes.bool,
		loginWithRedirect: PropTypes.func,
		logout: PropTypes.func,
		getAccessTokenSilently: PropTypes.func
	}).isRequired,
	logIn: PropTypes.func.isRequired,
	verifyToken: PropTypes.func.isRequired,
	agent: PropTypes.shape({
		isFullAutomatic: PropTypes.bool,
		isAutoAcceptInteraction: PropTypes.bool,
		agentBreakStatus: PropTypes.shape({
			emoji: PropTypes.string,
			status: PropTypes.string,
			preBreak: PropTypes.bool
		}),
		phoneData: PropTypes.shape({
			name: PropTypes.string,
			station: PropTypes.number,
			token: PropTypes.string
		}),
		breakStatus: PropTypes.shape({
			blockInteractionsOnBreak: PropTypes.bool
		}).isRequired,
		loading: PropTypes.bool.isRequired,
		info: PropTypes.shape({
			id: PropTypes.number,
			auth0UserID: PropTypes.string,
			name: PropTypes.string,
			email: PropTypes.string,
			licenseCode: PropTypes.string,
			date_creation: PropTypes.string,
			profile: PropTypes.string,
			hasActiveDepartment: PropTypes.bool,
			chameleonHash: PropTypes.string,
			account: PropTypes.shape({
				id: PropTypes.number,
				organizationId: PropTypes.string,
				name: PropTypes.string,
				partner_id: PropTypes.string
			}),
			totalVoice: PropTypes.shape({
				station: PropTypes.number
			})
		}),
		authenticated: PropTypes.bool,
		unauthorized: PropTypes.bool,
		error: PropTypes.string
	}).isRequired,
	registered: PropTypes.func.isRequired,
	unRegistered: PropTypes.func.isRequired,
	onNewInteraction: PropTypes.func.isRequired,
	onAccepted: PropTypes.func.isRequired,
	onUpdateInteraction: PropTypes.func.isRequired,
	onNewMessage: PropTypes.func.isRequired,
	onDeliveredMessage: PropTypes.func.isRequired,
	onReceivedMessage: PropTypes.func.isRequired,
	onNewWhatsappVoiceCall: PropTypes.func.isRequired,
	onWhatsappCallDuration: PropTypes.func.isRequired,
	onTyping: PropTypes.func.isRequired,
	onCleared: PropTypes.func.isRequired,
	onTransferred: PropTypes.func.isRequired,
	onFinished: PropTypes.func.isRequired,
	removeInteraction: PropTypes.func.isRequired,
	shouldNotify: PropTypes.bool.isRequired,
	onUnauthorized: PropTypes.func.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	fetchIdentity: PropTypes.func.isRequired,
	defineDefaultIdentity: PropTypes.func.isRequired,
	defaultIdentity: PropTypes.bool,
	identity: PropTypes.shape({
		login_color: PropTypes.string
	}),
	template: PropTypes.string.isRequired,
	fetchTaskCount: PropTypes.func.isRequired,
	activePhoneInterface: PropTypes.func.isRequired,
	definePhoneConnectionData: PropTypes.func.isRequired,
	onUpdateCallStatus: PropTypes.func.isRequired,
	defineSelectedInteraction: PropTypes.func.isRequired,
	fetchInteractionsCount: PropTypes.func.isRequired,
	fetchMissedInteractions: PropTypes.func.isRequired,
	updateAgentBreakStatus: PropTypes.func.isRequired,
	showReceptiveModal: PropTypes.bool.isRequired,
	onShowReceptiveModal: PropTypes.func.isRequired,
	onHideReceptiveModal: PropTypes.func.isRequired,
	currentInteractionHash: PropTypes.string.isRequired,
	onChangeInputPhone: PropTypes.func.isRequired,
	defineMainboxSection: PropTypes.func.isRequired,
	updateCreatingInteraction: PropTypes.func.isRequired,
	creatingInteraction: PropTypes.shape({
		type: PropTypes.string,
		info: PropTypes.string,
		taskId: PropTypes.number,
		finishInteraction: PropTypes.func
	}).isRequired,
	showingInternalMessages: PropTypes.bool.isRequired,
	showingAddCustomer: PropTypes.bool.isRequired,
	hideAddCustomer: PropTypes.func.isRequired,
	setInteractionList: PropTypes.func.isRequired,
	addCustomerClear: PropTypes.func.isRequired,
	defineTemplate: PropTypes.func.isRequired,
	showingEditCustomer: PropTypes.bool.isRequired,
	hideEditCustomer: PropTypes.func.isRequired,
	setOnlyChatOrPhone: PropTypes.func.isRequired,
	updateInteractionStatus: PropTypes.func.isRequired,
	onShowCall: PropTypes.func.isRequired,
	onFinish: PropTypes.func.isRequired,
	onFinishTask: PropTypes.func.isRequired,
	onFinishInteractionTask: PropTypes.func.isRequired,
	onInformExpiringSoonTaks: PropTypes.func.isRequired,
	informExpiringSoon: PropTypes.bool.isRequired,
	notifications: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		content: PropTypes.isRequired,
		title: PropTypes.string.isRequired,
		type: PropTypes.string
	})).isRequired,
	addNotification: PropTypes.func.isRequired,
	interactions: PropTypes.arrayOf(PropTypes.shape({
		interactionHash: PropTypes.string,
		currentState: PropTypes.string
	})).isRequired,
	pendingInteractions: PropTypes.arrayOf(PropTypes.shape({
		interactionHash: PropTypes.string,
		currentState: PropTypes.string
	})).isRequired,
	loadInteractionInfo: PropTypes.func.isRequired,
	sentMessages: PropTypes.arrayOf(PropTypes.shape({
		message: PropTypes.shape({
			content: PropTypes.string,
			contentType: PropTypes.string
		}),
		interactionHash: PropTypes.string
	})).isRequired,
	onSendMessage: PropTypes.func.isRequired,
	tokenExpired: PropTypes.bool.isRequired,
	updateUserID: PropTypes.func.isRequired,
	updateCallDuration: PropTypes.func,
	whatsappCallDuration: PropTypes.shape({
		interactionHash: PropTypes.string
	})

};

const mapActionsToProps = dispatch => ({
	logIn: (token, setOnlyChatOrPhone) => dispatch(actions.logIn(token, setOnlyChatOrPhone)),
	verifyToken: (token, setOnlyChatOrPhone, isLogin) => dispatch(actions.verifyToken(token, setOnlyChatOrPhone, isLogin)),
	registered: () => dispatch(actions.registered()),
	unRegistered: () => dispatch(actions.unRegistered()),
	updateUserID: token => dispatch(actions.updateUserID(token)),
	onUnauthorized: () => dispatch(actions.setUnauthorized()),
	onNewInteraction: interaction => dispatch(actions.onNewInteraction(interaction)),
	updateAgentBreakStatus: info => dispatch(actions.updateAgentBreakStatus(info)),
	fetchInteractionsCount: () => dispatch(actions.fetchInteractionsCount()),
	fetchMissedInteractions: (page, reset) => dispatch(actions.fetchMissedInteractions(page, reset)),
	onAccepted: (interactionHash, status) => dispatch(
		actions.onAcceptedInteraction(interactionHash, status)
	),
	onUpdateInteraction: (interaction, agent) => dispatch(
		actions.onUpdateInteraction(interaction, agent)
	),
	onNewMessage: (newMessage, interactionHash) => dispatch(
		actions.onNewMessage(newMessage, interactionHash)
	),
	onDeliveredMessage: deliveredMessage => dispatch(
		actions.onDeliveredMessage(deliveredMessage)
	),
	onReceivedMessage: receivedMessage => dispatch(
		actions.onReceivedMessage(receivedMessage)
	),
	onNewWhatsappVoiceCall: call => dispatch(actions.onNewWhatsappVoiceCall(call)),
	onTerminateWhatsappVoiceCall: call => dispatch(actions.onTerminateWhatsappVoiceCall(call)),
	onWhatsappCallDuration: call => dispatch(actions.onWhatsappCallDuration(call)),
	onTyping: interactionHash => dispatch(actions.onTyping(interactionHash)),
	onCleared: interactionHash => dispatch(actions.onCleared(interactionHash)),
	onTransferred: interactionHash => dispatch(actions.onTransferred(interactionHash)),
	onFinished: data => (dispatch(actions.onFinished(data))),
	removeInteraction: interactionHash => (dispatch(actions.removeInteraction(interactionHash))),
	fetchIdentity: url => dispatch(actions.fetchIdentity(url)),
	defineDefaultIdentity: () => dispatch(actions.defineDefaultIdentity()),
	fetchTaskCount: () => dispatch(actions.fetchTaskCount()),
	activePhoneInterface: () => dispatch(actions.activePhoneInterface()),
	definePhoneConnectionData: data => dispatch(actions.definePhoneConnectionData(data)),
	onUpdateCallStatus: status => dispatch(actions.updateCallStatus(status)),
	defineSelectedInteraction: hash => dispatch(actions.defineSelectedInteraction(hash)),
	onShowReceptiveModal: () => dispatch(actions.showReceptiveModal()),
	onHideReceptiveModal: () => dispatch(actions.hideReceptiveModal()),
	onChangeInputPhone: value => dispatch(actions.changeInputPhone(value)),
	defineMainboxSection: section => dispatch(actions.defineMainboxSection(section)),
	updateCreatingInteraction: bool => dispatch(actions.updateCreatingInteraction(bool)),
	setInteractionList: list => dispatch(actions.setInteractionList(list)),
	hideAddCustomer: () => dispatch(actions.hideAddCustomer()),
	addCustomerClear: () => dispatch(actions.addCustomerClear()),
	defineTemplate: template => dispatch(actions.defineTemplate(template)),
	hideEditCustomer: () => dispatch(actions.hideEditCustomer()),
	setOnlyChatOrPhone: onlyChatOrPhone => dispatch(actions.setOnlyChatOrPhone(onlyChatOrPhone)),
	updateInteractionStatus: (status, hash) => (
		dispatch(actions.updateInteractionStatus(status, hash))
	),
	onShowCall: interactionHash => dispatch(actions.showCall(interactionHash)),
	onFinish: interactionInfo => dispatch(actions.onFinish(interactionInfo)),
	onFinishTask: taskId => dispatch(actions.finishTask(taskId)),
	onFinishInteractionTask: taskId => dispatch(actions.finishInteractionTask(taskId)),
	onInformExpiringSoonTaks: (inform, socket) => dispatch(
		actions.informExpiringSoonTasks(inform, socket)
	),
	addNotification: notification => dispatch(actions.addNotification(notification)),
	loadInteractionInfo: (accountId, interactionHash) => (
		dispatch(actions.loadInteractionInfo(accountId, interactionHash))
	),
	onSendMessage: (sentMessage, interactionHash) => dispatch(actions.onSendMessage(
		sentMessage, interactionHash
	)),
	updateCallDuration: callDuration => dispatch(actions.updateCallDuration(callDuration))
});

const mapStateToProps = state => ({
	agent: state.agent,
	shouldNotify: state.agent.notify,
	defaultIdentity: state.interface.defaultIdentity,
	identity: state.interface.identity,
	template: state.interface.template,
	showReceptiveModal: state.interface.showReceptiveModal,
	currentInteractionHash: state.interaction.currentInteractionHash,
	creatingInteraction: state.agent.creatingInteraction,
	showingInternalMessages: state.interface.showingInternalMessages,
	showingAddCustomer: state.interface.showingAddCustomer,
	showingEditCustomer: Object.keys(state.interface.showingEditCustomer).length > 0,
	informExpiringSoon: state.agent.informExpiringSoon,
	notifications: state.interface.notifications,
	interactions: state.interaction.interactions,
	pendingInteractions: state.interaction.pendingInteractions,
	sentMessages: state.interaction.sentMessages,
	tokenExpired: state.interface.tokenExpired,
	whatsappCallDuration: state.interaction.whatsappCallDuration,
	callDuration: state.interaction.callDuration
});

export default withAuth0(connect(mapStateToProps, mapActionsToProps)(injectIntl(App)));
