import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const ONE_SECOND = 1000;
const ONE_MINUTE = ONE_SECOND * 60;
const ONE_HOUR = ONE_MINUTE * 60;

const BreakStatusTimer = ({ className = '', agentBreakStatus }) => {
	const [breakStatusTime, setBreakStatusTime] = useState('--');
	const { startedAt } = agentBreakStatus;

	const getBreakStatusTime = useCallback((breakStatusDate) => {
		const timeDifference = Math.abs(Date.now() - breakStatusDate);

		const restHours = timeDifference % ONE_HOUR;
		const restMinutes = restHours % ONE_MINUTE;

		const hours = Math.floor(timeDifference / ONE_HOUR).toString().padStart(2, '0');
		const minutes = Math.floor(restHours / ONE_MINUTE).toString().padStart(2, '0');
		const seconds = Math.floor(restMinutes / ONE_SECOND).toString().padStart(2, '0');

		if (hours > 0) {
			setBreakStatusTime(`${hours}h ${minutes}m ${seconds}s`);
		} else if (minutes > 0) {
			setBreakStatusTime(`${minutes}m ${seconds}s`);
		} else {
			setBreakStatusTime(`${seconds}s`);
		}
	}, []);

	useEffect(() => {
		if (!startedAt) return () => null;

		getBreakStatusTime(startedAt);

		const interval = setInterval(() => {
			getBreakStatusTime(startedAt);
		}, 1000);

		return () => clearInterval(interval);
	}, [getBreakStatusTime, startedAt]);

	return <span className={className}>{breakStatusTime}</span>;
};

const mapStateToProps = ({ agent }) => ({
	agentBreakStatus: agent.agentBreakStatus
});

BreakStatusTimer.propTypes = {
	className: PropTypes.string,
	agentBreakStatus: PropTypes.shape({
		startedAt: PropTypes.number
	}).isRequired
};

export default connect(mapStateToProps)(BreakStatusTimer);
