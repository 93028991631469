/* eslint-disable react/no-danger */

import React from 'react';
import PropTypes from 'prop-types';
import Linkify from 'omz-react-linkify';
import Bubble from '../../../Atoms/Bubble';

const MessageText = ({ messageInfo, children }) => (
	<Bubble messageInfo={messageInfo}>
		{children}
		<Linkify properties={{ target: '_blank' }}>
			{messageInfo.content}
		</Linkify>
	</Bubble>
);

MessageText.propTypes = {
	messageInfo: PropTypes.shape({
		content: PropTypes.string.isRequired,
		referenceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
	}).isRequired
};

export default MessageText;
