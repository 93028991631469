import React from 'react';
import PropTypes from 'prop-types';

import Template from '../Templates';

const Page = ({ template }) => <Template template={template} />;

Page.propTypes = {
	template: PropTypes.string.isRequired
};

export default Page;
