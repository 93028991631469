import './index.sass';

import React, {
	useEffect,
	useState,
	useCallback,
	useRef
} from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';

import * as actions from '../../../store/actions';

import Text from '../../Atoms/Text';
import ImgSvg from '../../Atoms/ImgSvg';
import Spinner from '../../Atoms/Spinner';
import Wrapper from '../../Atoms/Wrapper';
import Tooltip from '../../Atoms/Tooltip';
import RadioButton from '../../Atoms/RadioButton';
import Modal from '../../Molecules/Modal';
import Input from '../../Molecules/Inputs/Common';
import InfiniteList from '../../Molecules/InfiniteList';
import Button from '../../Molecules/Buttons/Button';

const messages = defineMessages({
	breakStatusTitle: {
		id: 'breakStatus.title',
		defaultMessage: 'Atualizar status de pausa'
	},
	breakStatusPlaceholder: {
		id: 'breakStatus.placeholder',
		defaultMessage: 'Buscar status de pausa'
	},
	breakStatusPreBreak: {
		id: 'breakStatus.preBreak',
		defaultMessage: 'Pré-pausa'
	},
	breakStatusBreak: {
		id: 'breakStatus.break',
		defaultMessage: 'Pausa'
	},
	breakStatusSave: {
		id: 'breakStatus.save',
		defaultMessage: 'Atualizar status'
	},
	breakStatusTooltipPreBreak: {
		id: 'breakStatus.tooltip.preBreak',
		defaultMessage: 'Ao selecionar a pré-pausa você poderá seguir realizando e finalizando atendimentos em andamento, mas sem receber novos.'
	},
	breakStatusTooltipBreak: {
		id: 'breakStatus.tooltip.break',
		defaultMessage: 'Ao selecionar a pausa, você irá pausar o andamento dos atendimentos atuais.'
	}
});

const BreakStatus = ({
	hideBreakStatus,
	fetchBreakStatus,
	updateAgentBreakStatus,
	agentBreakStatus = {},
	breakStatus = {
		data: [],
		page: 1,
		ended: false,
		loading: false
	},
	intl
}) => {
	const searchRef = useRef();
	const [searchValue, setSearchValue] = useState('');
	const [selectedBreakStatus, setSelectedBreakStatus] = useState(agentBreakStatus);
	const {
		loading,
		data,
		page,
		ended
	} = breakStatus;

	useEffect(() => {
		fetchBreakStatus();
	}, [fetchBreakStatus]);

	const onSearch = useCallback((search) => {
		setTimeout(() => {
			if (searchRef.current
				&& searchRef.current.input
				&& searchRef.current.input.current
				&& searchRef.current.input.current.value === search
			) {
				fetchBreakStatus({ search });
			}
		}, 1200);
	}, [searchRef, fetchBreakStatus]);

	const onChangeBreakStatusSearch = (e) => {
		const { value } = e.target;

		setSearchValue(value);
		onSearch(value);
	};

	const onChangeBreakStatusChoice = (name) => {
		const preBreak = name === 'preBreakStatus';

		setSelectedBreakStatus({ ...selectedBreakStatus, preBreak });
	};

	const onBottomBreakStatusList = () => {
		if (ended) return;

		fetchBreakStatus({ page, search: searchValue });
	};

	const onSelectBreakStatusItem = (breakStatusItem) => {
		const { preBreak } = selectedBreakStatus;

		setSelectedBreakStatus({ ...breakStatusItem, preBreak });
	};

	const onSaveBreakStatus = () => {
		const params = {
			breakStatus: selectedBreakStatus,
			action: 'PAUSE'
		};

		updateAgentBreakStatus(params);
		hideBreakStatus();
	};

	const canSaveBreakStatus = selectedBreakStatus.id !== undefined && selectedBreakStatus.preBreak !== undefined;

	return (
		<Modal onClose={hideBreakStatus} size="md" className="BreakStatusModal" isDark>
			<div className="BreakStatus" data-testid="component-breakStatus">
				<Text className="BreakStatus__title" size="large">{intl.formatMessage(messages.breakStatusTitle)}</Text>

				<div className="BreakStatus__choice">
					{/* eslint-disable-next-line jsx-a11y/label-has-for */}
					<label
						className="BreakStatus__choice--item"
						htmlFor="preBreakStatus"
					>
						<RadioButton
							checked={!!selectedBreakStatus.preBreak}
							id="preBreakStatus"
							onChange={e => onChangeBreakStatusChoice(e.target.name)}
							data-testid="component-breakStatus-inputPreBreak"
						/>
						{intl.formatMessage(messages.breakStatusPreBreak)}
						<Tooltip header={<ImgSvg name="info-circle" />} position="right">
							{intl.formatMessage(messages.breakStatusTooltipPreBreak)}
						</Tooltip>
					</label>
					{/* eslint-disable-next-line jsx-a11y/label-has-for */}
					<label
						className="BreakStatus__choice--item"
						htmlFor="breakStatus"
					>
						<RadioButton
							checked={selectedBreakStatus.preBreak === false}
							id="breakStatus"
							onChange={e => onChangeBreakStatusChoice(e.target.name)}
							data-testid="component-breakStatus-inputBreak"
						/>
						{intl.formatMessage(messages.breakStatusBreak)}
						<Tooltip header={<ImgSvg name="info-circle" />} position="right">
							{intl.formatMessage(messages.breakStatusTooltipBreak)}
						</Tooltip>
					</label>
				</div>
				<Input
					ref={searchRef}
					value={searchValue}
					onChange={onChangeBreakStatusSearch}
					placeholder={intl.formatMessage(messages.breakStatusPlaceholder)}
					data-testid="component-breakStatus-inputSearch"
				/>
				<InfiniteList
					onBottom={onBottomBreakStatusList}
					customClass="BreakStatus__list"
					data-testid="component-breakStatus-list"
				>
					{data.map(({
						id,
						emoji,
						status
					}) => (
						<Wrapper
							key={id}
							className="BreakStatus__list--item"
							action={() => {
								onSelectBreakStatusItem({
									id,
									emoji,
									status
								});
							}}
							data-testid="component-breakStatus-listItem"
						>
							<div className="BreakStatus__list--item-emote">{emoji}</div>
							<div className="BreakStatus__list--item-label">
								<Text size="big">{status}</Text>
							</div>
							{selectedBreakStatus.id === id && (
								<div className="BreakStatus__list--item-selected" data-testid="component-breakStatus-selectedItem">
									<ImgSvg name="confirm-changes" />
								</div>
							)}
						</Wrapper>
					))}
				</InfiniteList>
				<Button
					className="BreakStatus__button"
					click={() => onSaveBreakStatus()}
					active={canSaveBreakStatus}
					data-testid="component-breakStatus-buttonSave"
				>
					{intl.formatMessage(messages.breakStatusSave)}
				</Button>
				{loading && !data.length && <Spinner />}
			</div>
		</Modal>
	);
};

BreakStatus.propTypes = {
	hideBreakStatus: PropTypes.func.isRequired,
	fetchBreakStatus: PropTypes.func.isRequired,
	updateAgentBreakStatus: PropTypes.func.isRequired,
	agentBreakStatus: PropTypes.shape({
		id: PropTypes.number,
		emoji: PropTypes.string,
		status: PropTypes.string,
		preBreak: PropTypes.bool
	}),
	breakStatus: PropTypes.shape({
		data: PropTypes.arrayOf(PropTypes.shape({
			id: PropTypes.number,
			emoji: PropTypes.string,
			status: PropTypes.string
		})),
		page: PropTypes.number,
		ended: PropTypes.bool,
		loading: PropTypes.bool
	}),
	intl: PropTypes.shape({
		formatMessage: PropTypes.func
	}).isRequired
};

const mapStateToProps = ({ agent }) => ({
	breakStatus: agent.breakStatus,
	agentBreakStatus: agent.agentBreakStatus
});

const mapActionsToProps = dispatch => ({
	hideBreakStatus: () => dispatch(actions.hideBreakStatus()),
	fetchBreakStatus: info => dispatch(actions.fetchBreakStatus(info)),
	updateAgentBreakStatus: info => dispatch(actions.updateAgentBreakStatus(info))
});

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(BreakStatus));
