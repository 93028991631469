/* eslint-disable react/no-unknown-property */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';

import './index.sass';
import CheckTick from '../CheckTick';
import ImgSvg from '../ImgSvg';

const messages = defineMessages({
	title: {
		id: 'transferBubble.title',
		defaultMessage: 'Motivo da transferência'
	}
});

const Bubble = ({
	children,
	className = '',
	timeOnBottom,
	messageInfo,
	settings,
	intl
}) => {
	const {
		isFromAgent,
		isChatHistory,
		createdAt,
		status,
		attachments,
		messageId,
		externalId,
		rejected = false,
		origin
	} = messageInfo;

	const { formatMessage } = intl;

	const normalizedOrigin = origin && origin.toUpperCase();

	const classes = useMemo(() => [
		'Bubble',
		className,
		timeOnBottom && 'Bubble--timeOnBottom',
		isChatHistory && 'Bubble--isChatHistory',
		normalizedOrigin === 'CLIENT' && 'Bubble--client',
		normalizedOrigin === 'TRANSFER' && 'Bubble--transfer',
		normalizedOrigin === 'AGENT' && 'Bubble--agent',
		rejected && 'Bubble--rejected'
	].filter(Boolean).join(' '), [className, timeOnBottom, rejected, normalizedOrigin, isChatHistory]);

	return (
		<div className={classes} externalid={externalId} messageid={messageId}>
			{normalizedOrigin === 'TRANSFER' && (
				<h3 className="Bubble__title" style={settings}>{formatMessage(messages.title)}</h3>
			)}
			<div style={settings} className="Bubble__content">
				{children}
				{attachments && attachments.length > 0 && (
					<div className="Bubble__attachments">
						{attachments.map(item => (
							<a
								key={item}
								className="Bubble__attachments__item"
								href={item}
								target="blank"
							>
								<ImgSvg name="attach-off" />
							</a>
						))}
					</div>
				)}
			</div>
			{normalizedOrigin !== 'TRANSFER' && createdAt && (
				<div style={settings} className="Bubble__info">
					<span>{createdAt}</span>
					{!rejected && isFromAgent && <CheckTick status={status} />}
				</div>
			)}
		</div>
	);
};

Bubble.propTypes = {
	settings: PropTypes.shape({
		fontSize: PropTypes.string
	}).isRequired,
	messageInfo: PropTypes.shape({
		isFromAgent: PropTypes.bool,
		createdAt: PropTypes.string,
		status: PropTypes.string,
		attachments: PropTypes.arrayOf(PropTypes.string),
		interactionType: PropTypes.string,
		messageId: PropTypes.number,
		externalId: PropTypes.string,
		rejected: PropTypes.bool,
		isChatHistory: PropTypes.bool,
		origin: PropTypes.string
	}).isRequired,
	timeOnBottom: PropTypes.bool,
	className: PropTypes.string,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
};

const mapStateToProps = state => ({
	settings: state.agent.info.settings
});

export default connect(mapStateToProps)(injectIntl(Bubble));
