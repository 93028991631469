import './index.sass';

import React, {
	Fragment, useEffect, useCallback, useRef
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';
import { MESSAGE_CONTENT_TYPE, MESSAGE_ORIGIN, MESSAGE_STATUS } from '../../../shared/consts';
import { timeInfoFormat2, timeInfoFormat4, mapNewMessageItem } from '../../../shared/utility';
import InfiniteList from '../../Molecules/InfiniteList';
import MessageWrapper from '../../Molecules/Messages/MessageWrapper';
import MessageDate from '../../Molecules/Messages/MessageDate';
import MessageTyping from '../../Molecules/Messages/MessageTyping';
import ImgSvg from '../../Atoms/ImgSvg';
import Text from '../../Atoms/Text';

const translations = defineMessages({
	rejected: {
		id: 'messageState.rejected',
		defaultMessage: 'A mensagem acima não pôde ser enviada, pois o cliente está sendo atendido em outra plataforma da Zenvia.'
	},
	you: {
		id: 'messageList.you',
		defaultMessage: 'Você'
	}
});

const MessageList = ({
	typing,
	messages = [],
	loadMoreMessages = () => {},
	intl,
	currentInteraction,
	settings,
	isInternalHistory,
	isChatHistory,
	currentAgentName = ''
}) => {
	let lastDate = null;
	const { formatMessage } = intl;
	const { currentState, interactionType, rejected = false } = currentInteraction;
	const formattedMessages = messages.map(message => mapNewMessageItem(message));
	const messagesEndRef = useRef(null);

	const scrollToBottom = useCallback(() => {
		if (messagesEndRef.current && !isChatHistory) {
			messagesEndRef.current.scrollIntoView();
		}
	}, [isChatHistory]);

	useEffect(() => {
		setTimeout(() => {
			scrollToBottom();
		}, 10);
	}, [messages, scrollToBottom]);


	// Search for referenced messages and inserts them into the original message.
	formattedMessages.forEach((message, key) => {
		if (message.referenceId) {
			const referencedMessage = formattedMessages.find(
				x => x.messageId === message.referenceId
			);
			formattedMessages[key].referencedMessage = {
				agentName: referencedMessage.agentName,
				referenceId: referencedMessage.messageId,
				content: referencedMessage.content,
				contentType: referencedMessage.contentType,
				url: referencedMessage.url
			};
		}
	});

	const { fontSize = '14px' } = settings;

	return (
		<InfiniteList customClass="MessageList" onTop={loadMoreMessages} startOnBottom autoScrollDown>
			<div style={{ flex: '1 1 auto' }} />
			{formattedMessages && formattedMessages.map((message) => {
				const {
					createdAt,
					messageId = new Date().getTime(),
					origin,
					content = '',
					contentType,
					status
				} = message;
				let messageDate = null;

				// Before remove the next line, add the message label in MessageState
				if (contentType === MESSAGE_CONTENT_TYPE.STATE && status === MESSAGE_STATUS.NOT_ANSWER) return null;

				if (createdAt && timeInfoFormat4(createdAt) !== lastDate) {
					lastDate = timeInfoFormat4(createdAt);
					messageDate = <MessageDate fontSize={fontSize} key={lastDate} date={lastDate} />;
				}

				const messageComponent = (
					<MessageWrapper
						key={messageId}
						messageInfo={{
							...message,
							isInternalHistory,
							isChatHistory,
							agentName: currentAgentName === message.agentName ? formatMessage(translations.you) : message.agentName,
							content,
							isFromAgent: origin && origin.toLowerCase() === MESSAGE_ORIGIN.AGENT,
							isFromClient: origin && origin.toLowerCase() === MESSAGE_ORIGIN.CLIENT,
							isFromInternalAgent: origin && origin.toLowerCase() === MESSAGE_ORIGIN.INT_AGENT,
							isFromInternalAdmin: origin && origin.toLowerCase() === MESSAGE_ORIGIN.INT_ADMIN,
							createdAt: timeInfoFormat2(createdAt),
							interactionType,
							rejected
						}}
					/>
				);

				if (messageDate) {
					return (
						<Fragment key={messageId}>
							{messageDate}
							{messageComponent}
						</Fragment>
					);
				}

				return messageComponent;
			})}
			{rejected && (
				<div className="templateRejected">
					<ImgSvg name="exclamation-triangle-filled" />
					<Text>{formatMessage(translations.rejected)}</Text>
				</div>
			)}
			{!['ENDED', 'UNAVAILABLE'].includes(currentState) && typing && <MessageTyping /> }
			<div ref={messagesEndRef} />
		</InfiniteList>
	);
};

MessageList.propTypes = {
	messages: PropTypes.arrayOf(
		PropTypes.shape({
			user: PropTypes.string,
			type: PropTypes.string
		})
	),
	settings: PropTypes.shape({
		fontSize: PropTypes.string
	}).isRequired,
	currentAgentName: PropTypes.string,
	loadMoreMessages: PropTypes.func,
	typing: PropTypes.bool,
	isInternalHistory: PropTypes.bool,
	isChatHistory: PropTypes.bool,
	currentInteraction: PropTypes.shape({
		currentState: PropTypes.string,
		interactionType: PropTypes.string,
		origin: PropTypes.string,
		rejected: PropTypes.bool
	}).isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
};

const mapStateToProps = (state) => {
	const {
		currentInteractionHash,
		historyInteractions,
		interactions,
		missedInteractions,
		pendingInteractions
	} = state.interaction;

	const allInteractions = interactions.concat(pendingInteractions).concat(historyInteractions).concat(missedInteractions);

	return {
		currentInteraction: allInteractions.find(({ interactionHash }) => interactionHash === currentInteractionHash) || {},
		settings: state.agent.info.settings,
		currentAgentName: state.agent.info.name
	};
};

export default connect(mapStateToProps)(injectIntl(MessageList));
