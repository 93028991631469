import './index.sass';

import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import { MESSAGE_CONTENT_TYPE, CHANNELS_WITH_REPLY, SOURCE_WITH_REPLY } from '../../../../shared/consts';

import * as actions from '../../../../store/actions';

import MessageAttachment from '../MessageAttachment';
import AvatarName from '../../../Atoms/AvatarName';
import IconButton from '../../Buttons/IconButton';
import getMessageComponent from './utils';

const messages = defineMessages({
	reply: {
		id: 'messageWrapper.reply',
		defaultMessage: 'Responder'
	}
});

const MessageWrapper = ({
	messageInfo = {},
	onShowReplyMessage,
	onHideReplyMessage,
	currentInteractionHash,
	currentInteraction,
	showReplyMessage = false,
	intl
}) => {
	const {
		contentType,
		isFromAgent,
		isFromClient,
		agentPhoto,
		isChatHistory
	} = messageInfo;

	const isStateMessage = contentType === MESSAGE_CONTENT_TYPE.STATE;
	const hasMessage = messageInfo && messageInfo.contentType;
	const [classes, setClasses] = useState('');

	const onSelectReplyMessage = useCallback(() => {
		if (showReplyMessage) onHideReplyMessage(currentInteractionHash, messageInfo);
		return onShowReplyMessage(currentInteractionHash, messageInfo);
	}, [currentInteractionHash, showReplyMessage, onHideReplyMessage, onShowReplyMessage, messageInfo]);

	useEffect(() => {
		const currentClasses = ['MessageWrapper'];
		if (!isStateMessage) {
			if (messageInfo.origin === 'INT_ADMIN' || messageInfo.origin === 'INT_AGENT') {
				currentClasses.push('MessageWrapper--internal');
				if (messageInfo.isInternalHistory) {
					currentClasses.push(`MessageWrapper--${messageInfo.origin}`);
				}
			} else if (messageInfo.origin === 'TRANSFER') {
				currentClasses.push('MessageWrapper--transfer');
			} else if (isFromAgent) {
				currentClasses.push('MessageWrapper--agent');
			} else {
				currentClasses.push('MessageWrapper--client');
			}
		}
		setClasses(currentClasses.join(' '));
	}, [isStateMessage, isFromAgent, messageInfo]);

	return contentType !== MESSAGE_CONTENT_TYPE.DATE && (
		<div className={classes}>
			{hasMessage && (
				getMessageComponent(messageInfo) || <MessageAttachment messageInfo={messageInfo} />
			)}
			{(!isChatHistory && isFromAgent) && (
				<div className="MessageWrapper__avatar">
					<AvatarName url={agentPhoto} />
				</div>
			)}
			{
				isFromClient
			&& CHANNELS_WITH_REPLY.find(channel => channel === messageInfo.interactionType)
			&& SOURCE_WITH_REPLY.find(source => currentInteraction.source && currentInteraction.source.includes(source))
				&& (
					<div className="MessageWrapper__reply">
						<IconButton
							name="reply"
							click={onSelectReplyMessage}
							fill="grey-light"
							info={intl.formatMessage(messages.reply)}
							rounded
						/>
					</div>
				)}
		</div>
	);
};

MessageWrapper.propTypes = {
	messageInfo: PropTypes.shape({
		interactionType: PropTypes.string,
		contentType: PropTypes.string,
		isFromAgent: PropTypes.bool,
		agentPhoto: PropTypes.string,
		agentName: PropTypes.string,
		rejected: PropTypes.bool,
		isChatHistory: PropTypes.bool
	}).isRequired,
	onShowReplyMessage: PropTypes.func.isRequired,
	onHideReplyMessage: PropTypes.func.isRequired,
	currentInteractionHash: PropTypes.string.isRequired,
	showReplyMessage: PropTypes.bool.isRequired,
	currentInteraction: PropTypes.shape({
		source: PropTypes.string
	}).isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
};

const MapActionsToProps = dispatch => ({
	onShowReplyMessage: (interactionHash, messageId) => dispatch(actions.showReplyMessage(interactionHash, messageId)),
	onHideReplyMessage: interactionHash => dispatch(actions.hideReplyMessage(interactionHash))
});

const MapStateToProps = (state) => {
	const { interaction } = state;
	const {
		currentInteractionHash,
		interactions,
		pendingInteractions
	} = interaction;
	const allInteractions = interactions.concat(pendingInteractions);

	return ({
		currentInteraction: allInteractions.find(({ interactionHash }) => interactionHash === currentInteractionHash) || {},
		currentInteractionHash,
		showReplyMessage: state.interface.showingReplyMessage.some(item => item.hash === currentInteractionHash)
	});
};

export default connect(MapStateToProps, MapActionsToProps)(injectIntl(MessageWrapper));
