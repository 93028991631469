import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';

import './index.sass';
import * as actions from '../../../store/actions';
import { letSendAttachment, normalizeMimeType } from '../../../shared/utility';
import ImgSvg from '../../Atoms/ImgSvg';
import Tooltip from '../../Atoms/Tooltip';

const messages = defineMessages({
	attachmentLimitTitle: {
		id: 'attachment.attachmentLimitTitle',
		defaultMessage: 'Tamanho de arquivo não suportado'
	},
	attachmentLimitContent: {
		id: 'attachment.attachmentLimitContent',
		defaultMessage: 'deve ser menor que'
	},
	image: {
		id: 'attachment.image',
		defaultMessage: 'Imagem'
	},
	video: {
		id: 'attachment.video',
		defaultMessage: 'Vídeo'
	},
	audio: {
		id: 'attachment.audio',
		defaultMessage: 'Áudio'
	},
	any: {
		id: 'attachment.any',
		defaultMessage: 'Arquivo'
	},
	tooltipMessage: {
		id: 'attachment.tooltipMessage',
		defaultMessage: 'Anexar arquivo ou imagem'
	}
});

const Attachment = ({
	addNotification,
	disabled,
	onSendAttachment,
	agent,
	currentInteractionHash,
	interactionType = '',
	showReplyMessage,
	messageToReply = {},
	onHideReplyMessage,
	intl
}) => {
	const { formatMessage } = intl;
	const inputFile = useRef();

	const [classes, setClasses] = useState('');
	useEffect(() => {
		const currentClasses = ['Attachment__label'];
		if (disabled) currentClasses.push('Attachment__label--disabled');
		setClasses(currentClasses.join(' '));
	}, [disabled]);

	const onAttachment = () => {
		const formData = new FormData();

		formData.append('files[]', inputFile.current.files[0]);

		if (inputFile.current.files[0]) {
			const { name, type, size } = inputFile.current.files[0];
			const { approved, attachmentLimit } = letSendAttachment(size, type, interactionType);

			if (approved) {
				onSendAttachment({
					formData,
					agent,
					currentInteractionHash,
					fileInfo: {
						name,
						size,
						type: normalizeMimeType(name, type)
					},
					referenceId: showReplyMessage ? messageToReply.message.messageId : ''
				});
				if (showReplyMessage) onHideReplyMessage(currentInteractionHash);
			} else {
				addNotification({
					title: formatMessage(messages.attachmentLimitTitle),
					content: `${formatMessage(messages[attachmentLimit.type])} ${formatMessage(messages.attachmentLimitContent)} ${attachmentLimit.size}MB`,
					type: 'warning'
				});
			}
		}
	};

	return (
		<label className={classes} htmlFor="files">
			<input
				aria-label={formatMessage(messages.tooltipMessage)}
				type="file"
				id="files"
				accept={interactionType === 'INSTAGRAM' ? '.pg,.png,.ico,.bmp' : ''}
				className="Attachment__input"
				disabled={disabled}
				onChange={onAttachment}
				ref={inputFile}
			/>
			<Tooltip header={<ImgSvg name="attach-off" />} position="top">
				Anexar arquivo ou imagem
			</Tooltip>
		</label>
	);
};

Attachment.propTypes = {
	disabled: PropTypes.bool,
	onSendAttachment: PropTypes.func.isRequired,
	agent: PropTypes.shape({
		account: PropTypes.shape({
			id: PropTypes.string
		})
	}).isRequired,
	currentInteractionHash: PropTypes.string.isRequired,
	interactionType: PropTypes.string,
	addNotification: PropTypes.func.isRequired,
	showReplyMessage: PropTypes.bool.isRequired,
	messageToReply: PropTypes.shape({
		interactionHash: PropTypes.string,
		message: PropTypes.shape({
			interactionType: PropTypes.string,
			contentType: PropTypes.string,
			isFromAgent: PropTypes.bool,
			agentPhoto: PropTypes.string,
			agentName: PropTypes.string,
			content: PropTypes.string,
			externalId: PropTypes.string,
			messageId: PropTypes.number
		})
	}),
	onHideReplyMessage: PropTypes.func.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func
	}).isRequired
};

const mapStateToProps = state => ({
	agent: state.agent,
	currentInteractionHash: state.interaction.currentInteractionHash,
	showReplyMessage: state.interface.showingReplyMessage.some(item => item.hash === state.interaction.currentInteractionHash),
	messageToReply: state.interface.showingReplyMessage.find(item => item.hash === state.interaction.currentInteractionHash)
});

const mapActionsToProps = dispatch => ({
	onSendAttachment: info => dispatch(actions.sendAttachment(info)),
	addNotification: info => dispatch(actions.addNotification(info)),
	onHideReplyMessage: hash => dispatch(actions.hideReplyMessage(hash))
});

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(Attachment));
