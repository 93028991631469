import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import './index.sass';

import { MESSAGE_CONTENT_TYPE } from '../../../shared/consts';
import { letSendAttachment, normalizeMimeType } from '../../../shared/utility';

import * as actions from '../../../store/actions';
import Button from '../Buttons/Button';
import IconButton from '../Buttons/IconButton';
import Text from '../../Atoms/Text';
import ImgSvg from '../../Atoms/ImgSvg';

const messages = defineMessages({
	send: {
		id: 'dragAndDropFileUpload.send',
		defaultMessage: 'Enviar'
	},
	attach: {
		id: 'dragAndDropFileUpload.attach',
		defaultMessage: 'Anexar'
	},
	cancel: {
		id: 'dragAndDropFileUpload.cancel',
		defaultMessage: 'Cancelar'
	},
	attachmentLimitTitle: {
		id: 'dragAndDropFileUpload.attachmentLimitTitle',
		defaultMessage: 'Tamanho de arquivo não suportado'
	},
	attachmentLimitContent: {
		id: 'dragAndDropFileUpload.attachmentLimitContent',
		defaultMessage: 'O arquivo deve ser menor que'
	},
	dragTheFileHere: {
		id: 'dragAndDropFileUpload.dragTheFileHere',
		defaultMessage: 'Arraste o arquivo aqui'
	},
	previewUnavailable: {
		id: 'dragAndDropFileUpload.previewUnavailable',
		defaultMessage: 'Pré-visualização indisponível'
	},
	expandImage: {
		id: 'dragAndDropFileUpload.expandImage',
		defaultMessage: 'Expandir imagem'
	},
	downloadFile: {
		id: 'dragAndDropFileUpload.downloadFile',
		defaultMessage: 'Baixar arquivo'
	}
});

const DragAndDropFileUpload = ({
	agent,
	interactionType = '',
	currentInteractionHash,
	addNotification,
	onSendAttachmentMail,
	onSendAttachment,
	setHasFileDragged = () => {},
	newFile,
	open,
	onClose,
	intl
}) => {
	const [files, setFiles] = useState(newFile);
	const { getRootProps } = useDropzone({
		maxFiles: 1,
		onDrop: (acceptedFiles) => {
			setFiles(acceptedFiles.map(file => Object.assign(file, {
				preview: URL.createObjectURL(file)
			}))[0]);
		},
		validator: () => {
			setHasFileDragged(true);
		}
	});

	useEffect(() => {
		if (!open) {
			setFiles(null);
		}
	},
	[open]);

	const { formatMessage } = intl;
	const isEmail = ['offcontact', 'email'].includes(interactionType.toLowerCase());

	const getVerifiedContentType = (contentType) => {
		let result = contentType;

		if (result.match(MESSAGE_CONTENT_TYPE.IMAGE)) {
			result = MESSAGE_CONTENT_TYPE.IMAGE;
		} else if (result.match(MESSAGE_CONTENT_TYPE.AUDIO)) {
			result = MESSAGE_CONTENT_TYPE.AUDIO;
		} else if (result.match(MESSAGE_CONTENT_TYPE.VIDEO)) {
			result = MESSAGE_CONTENT_TYPE.VIDEO;
		} else if (result.match(MESSAGE_CONTENT_TYPE.PDF)) {
			result = MESSAGE_CONTENT_TYPE.PDF;
		} else if (result.match(MESSAGE_CONTENT_TYPE.TEXT)) {
			result = MESSAGE_CONTENT_TYPE.TEXT;
		}

		return result;
	};

	const getComponentByType = (messageInfo) => {
		const verifiedContentType = getVerifiedContentType(messageInfo.type);

		switch (verifiedContentType) {
		case MESSAGE_CONTENT_TYPE.IMAGE:
			return (
				<>
					<a className="FileUploadConfirmation__wrapper__content__file__icon" href={messageInfo.preview} target="_blank" rel="noopener noreferrer">
						<IconButton ariaLabel={formatMessage(messages.expandImage)} name="expand" fill="grey-light" />
					</a>
					<img src={messageInfo.preview} alt="media" />
				</>
			);
		case MESSAGE_CONTENT_TYPE.AUDIO:
			return (
				<audio src={messageInfo.preview} controls><track kind="captions" /></audio>
			);
		case MESSAGE_CONTENT_TYPE.VIDEO:
			return (
				<video src={messageInfo.preview} controls><track kind="captions" /></video>
			);
		case MESSAGE_CONTENT_TYPE.PDF:
		case MESSAGE_CONTENT_TYPE.TEXT:
			return (
				<>
					{/* eslint-disable-next-line jsx-a11y/alt-text */}
					<object width="600" height="600" alt="pdf" data={messageInfo.preview} />
				</>
			);
		default:
			return (
				<div className="DragAndDropFileUpload__wrapper__content__file__default">
					<a
						href={messageInfo.preview}
						target="_blank"
						download
						rel="noopener noreferrer"
						aria-label={formatMessage(messages.downloadFile)}
					>
						<ImgSvg name="attach-off" />
					</a>
					<Text>{formatMessage(messages.previewUnavailable)}</Text>
				</div>
			);
		}
	};

	const handleClose = () => {
		setFiles(null);
		onClose();
		setHasFileDragged(false);
	};

	const onAttachment = () => {
		const formData = new FormData();

		formData.append('files[]', files);

		if (files) {
			const { name, type, size } = files;
			const { approved, attachmentLimit } = letSendAttachment(size, type, interactionType);

			handleClose();

			if (approved) {
				onSendAttachment({
					formData,
					agent,
					currentInteractionHash,
					fileInfo: {
						name,
						size,
						type: normalizeMimeType(name, type)
					}
				});
			} else {
				addNotification({
					title: formatMessage(messages.attachmentLimitTitle),
					content: `${formatMessage(messages.attachmentLimitContent)} ${attachmentLimit.size}MB`,
					type: 'warning'
				});
			}
		}
	};

	const onAttachmentEmail = () => {
		const { type, size } = files;
		const { approved, attachmentLimit } = letSendAttachment(size, type, 'EMAIL');

		handleClose();

		if (approved) {
			const formData = new FormData();

			formData.append('files[]', files);

			onSendAttachmentMail({
				accountId: agent.info.account.id,
				interactionHash: currentInteractionHash,
				formData
			});
		} else {
			addNotification({
				title: formatMessage(messages.attachmentLimitTitle),
				content: `${formatMessage(messages.attachmentLimitContent)} ${attachmentLimit.size}MB`,
				type: 'warning'
			});
		}
	};

	return (
		<div style={{ height: open ? 'calc(100vh - 65px)' : 0, bottom: newFile ? -10 : 0 }} className="DragAndDropFileUpload">
			{
				!files ? (
					<div {...getRootProps({ className: 'dropzone' })}>
						<span>{formatMessage(messages.dragTheFileHere)}</span>
					</div>
				) : (
					<div style={{ height: 'calc(100vh - 160px)' }}>
						<div className="DragAndDropFileUpload__header">
							<Text>{files.name}</Text>
							<button
								onClick={handleClose}
								type="button"
							>
								<ImgSvg name="close" />
							</button>
						</div>
						<div className="DragAndDropFileUpload__wrapper">
							<div className="DragAndDropFileUpload__wrapper__content">
								<div className="DragAndDropFileUpload__wrapper__content__file">
									{getComponentByType(files)}
								</div>
							</div>
						</div>
						<div className="DragAndDropFileUpload__actions">
							<Button secondary click={handleClose}>{formatMessage(messages.cancel)}</Button>
							{isEmail
								? <Button click={onAttachmentEmail}>{formatMessage(messages.attach)}</Button>
								: <Button click={onAttachment}>{formatMessage(messages.send)}</Button>
							}
						</div>
					</div>
				)
			}
		</div>
	);
};

DragAndDropFileUpload.propTypes = {
	agent: PropTypes.shape({
		info: PropTypes.shape({
			account: PropTypes.shape({
				id: PropTypes.number
			})
		})
	}).isRequired,
	currentInteractionHash: PropTypes.string.isRequired,
	open: PropTypes.bool.isRequired,
	interactionType: PropTypes.string,
	onClose: PropTypes.func.isRequired,
	setHasFileDragged: PropTypes.func,
	onSendAttachment: PropTypes.func.isRequired,
	onSendAttachmentMail: PropTypes.func.isRequired,
	addNotification: PropTypes.func.isRequired,
	newFile: PropTypes.shape({}),
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
};

const mapStateToProps = state => ({
	agent: state.agent,
	currentInteractionHash: state.interaction.currentInteractionHash
});

const mapActionsToProps = dispatch => ({
	onSendAttachmentMail: info => dispatch(actions.sendEmailAttachment(info)),
	onSendAttachment: info => dispatch(actions.sendAttachment(info)),
	addNotification: info => dispatch(actions.addNotification(info))
});

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(DragAndDropFileUpload));
