/* eslint-disable no-tabs */
import axios from 'axios';
import * as actionTypes from './actionTypes';
import { fetchFormFields } from './formFields';
import { fetchCustomChannels } from './customChannel';
import { fetchWidgetAndIntegrations } from './widgetAndIntegration';
import { addNotification } from './interface';
import {
	decodeJWT,
	getCustomerFieldValue,
	getTimezone,
	isAuth0Enabled,
	isZenviaDomain
} from '../../shared/utility';

const locale = require(`../../locales/${localStorage.getItem('locale') || 'pt'}.json`);

export const registerBegin = () => ({
	type: actionTypes.REGISTER_AGENT_BEGIN
});
export function register() {
	return (dispatch) => {
		dispatch(registerBegin());
		window.omzVish.register();
	};
}

export const setNotify = () => ({
	type: actionTypes.SET_NOTIFY
});

export const updateAgentBreakStatusBegin = () => ({
	type: actionTypes.AGENT_BREAK_STATUS_BEGIN
});
export const updateAgentBreakStatusSuccess = payload => ({
	type: actionTypes.AGENT_BREAK_STATUS_SUCCESS,
	payload
});
export function updateAgentBreakStatus({ breakStatus, action, shouldChangeBreakStatusInBackend = true }) {
	return (dispatch) => {
		dispatch(updateAgentBreakStatusBegin());
		if (shouldChangeBreakStatusInBackend) window.omzVish.changeBreakStatus(breakStatus, action);
		dispatch(updateAgentBreakStatusSuccess({ breakStatus }));
	};
}

export const fetchBreakStatusBegin = () => ({
	type: actionTypes.FETCH_BREAK_STATUS_BEGIN
});
export const fetchBreakStatusSuccess = customerInfo => ({
	type: actionTypes.FETCH_BREAK_STATUS_SUCCESS,
	payload: customerInfo
});
export const fetchBreakStatusFailed = () => ({
	type: actionTypes.FETCH_BREAK_STATUS_FAILED
});
export const fetchBreakStatus = (info = {}) => (
	(dispatch) => {
		const { search = '', page = 1 } = info;

		dispatch(fetchBreakStatusBegin());
		return axios.get(`${process.env.OMZ_ZAPI}/admin/break_status?limit=20&page=${page}&column=status&direction=asc&active=true&search=${search}`)
			.then(({ data }) => {
				const { blockInteractionsOnBreak, breakStatus, status } = data;
				if (status === 200) {
					dispatch(fetchBreakStatusSuccess({
						fetchedBreakStatus: breakStatus,
						blockInteractionsOnBreak,
						page
					}));
				} else {
					dispatch(fetchBreakStatusFailed());
				}
			}).catch(() => {
				dispatch(fetchBreakStatusFailed());
			});
	}
);

export const logInBegin = () => ({
	type: actionTypes.LOGIN_AGENT_BEGIN
});
export const logInSuccess = user => ({
	type: actionTypes.LOGIN_AGENT_SUCCESS,
	payload: { user }
});
export const logInFailed = errorMessage => ({
	type: actionTypes.LOGIN_AGENT_FAILED,
	payload: errorMessage
});

export async function validateAccessHours(token) {
	try {
		const response = await fetch(`${process.env.OMZ_ZAPI}/admin/workdays/validate_access`, {
			headers: {
				Authorization: `${token}`
			}
		});
		if (!response.ok) {
			throw new Error('Failed to fetch validate_access endpoint');
		}
		const data = await response.json();
		if (!data) {
			throw new Error('No data returned from validate_access endpoint');
		}

		const { grantAccess } = data;

		if (grantAccess === false) {
			sessionStorage.setItem('accessDenied', 'true');
			sessionStorage.setItem('omz_token', '');
		} else if (grantAccess === true) {
			sessionStorage.setItem('accessDenied', 'false');
		}
		return grantAccess;
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('Error validating access:', error);
		return false;
	}
}

export function logIn(auth0, setOnlyChatOrPhone) {
	return (dispatch) => {
		dispatch(logInBegin());
		return auth0.getAccessTokenSilently()
			.then(token => axios.post(`${process.env.OMZ_ZAPI}/external/authentication/verify_auth0`, {
				token: token.toString()
			})
				.then(async ({ data }) => {
					if (data.status === 200) {
						await validateAccessHours(data.zchatToken);
						const decodedJson = decodeJWT(data.zchatToken);
						const { features, profile } = decodedJson;
						sessionStorage.setItem('login', true);
						sessionStorage.setItem('omz_token', data.zchatToken);
						dispatch(logInSuccess(decodedJson));
						dispatch(fetchFormFields(data.zchatToken));
						dispatch(fetchCustomChannels(data.zchatToken));
						dispatch(fetchWidgetAndIntegrations());
						const onlyChatOrPhoneFeature = features.find(feature => (feature.name === 'only_chat_or_phone'));
						const onlyChatOrPhone = onlyChatOrPhoneFeature ? onlyChatOrPhoneFeature.status === 'true' : true;
						setOnlyChatOrPhone(onlyChatOrPhone);

						const adminOnlineFeature = features.find(feature => (feature.name === 'admin_online'));
						const adminOnline = adminOnlineFeature && adminOnlineFeature.status === 'true';

						const agentCustomOnlineFeature = features.find(feature => (feature.name === 'agent_custom_online'));
						const agentCustomOnline = agentCustomOnlineFeature && agentCustomOnlineFeature.status === 'true';

						const agentOfflineFeature = features.find(feature => (feature.name === 'agent_offline'));
						const agentOffline = agentOfflineFeature && agentOfflineFeature.status === 'true';

						if ((!['ADMIN', 'AGENT'].includes(profile) && !agentCustomOnline) || (profile === 'ADMIN' && !adminOnline) || (profile === 'AGENT' && agentOffline) || (profile === 'ACCESS_SUPPORT')) return;

						dispatch(register());

						const activeNotification = localStorage.getItem('activeNotification');
						if (activeNotification === 'true') dispatch(setNotify());
					} else {
						dispatch(logInFailed(data.message));
					}
				}).catch((e) => {
					dispatch(logInFailed(e.toString()));
					window.sessionStorage.clear();
					auth0.logout({ returnTo: window.location.origin });
				}));
	};
}

export function refreshToken() {
	return dispatch => axios.get(`${process.env.OMZ_ZAPI}/external/authentication/refresh`, {}, {
		headers: {
			Authorization: sessionStorage.getItem('omz_token')
		}
	})
		.then(({ data }) => {
			if (data.status === 200) {
				const decodedJson = decodeJWT(data.token);
				sessionStorage.setItem('omz_token', data.token);
				dispatch(logInSuccess(decodedJson));
			} else {
				dispatch(logInFailed(data.message));
			}
		}).catch(e => dispatch(logInFailed(e.toString())));
}

export function verifyToken(token, setOnlyChatOrPhone, isLogin = false) {
	return (dispatch) => {
		dispatch(logInBegin());
		return axios.get(`${process.env.OMZ_ZAPI}/external/authentication/verify`, {}, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		})
			.then(({ data }) => {
				if (data.status === 200) {
					const decodedJson = decodeJWT(data.token);
					const { features } = decodedJson;
					sessionStorage.setItem('login', isLogin);
					sessionStorage.setItem('omz_token', data.token);
					dispatch(logInSuccess(decodedJson));
					dispatch(fetchFormFields(data.token));
					dispatch(fetchCustomChannels(data.token));
					dispatch(fetchWidgetAndIntegrations());
					const onlyChatOrPhoneFeature = features.find(feature => (feature.name === 'only_chat_or_phone'));
					const onlyChatOrPhone = onlyChatOrPhoneFeature ? onlyChatOrPhoneFeature.status === 'true' : true;
					setOnlyChatOrPhone(onlyChatOrPhone);

					const activeNotification = localStorage.getItem('activeNotification');
					const { status, breakStatus } = JSON.parse(window.localStorage.getItem('lastAgentStatus'));

					// eslint-disable-next-line default-case
					switch (status) {
					case 'ONLINE':
						if (activeNotification === 'true') dispatch(setNotify());
						return dispatch(register());
					case 'BREAK': {
						const params = {
							breakStatus,
							action: 'PAUSE',
							shouldChangeBreakStatusInBackend: false
						};
						if (!breakStatus.preBreak) dispatch(fetchBreakStatus());

						return dispatch(updateAgentBreakStatus(params));
					}
					}
				}
				return dispatch(logInFailed(data.message));
			}).catch(e => dispatch(logInFailed(e.toString())));
	};
}

export const authBegin = () => ({
	type: actionTypes.AUTH_AGENT_BEGIN
});
export const authSuccess = () => ({
	type: actionTypes.AUTH_AGENT_SUCCESS
});
export const authFailed = () => ({
	type: actionTypes.AUTH_AGENT_FAILED
});

export const auth = info => (
	(dispatch) => {
		dispatch(authBegin());
		const { username, password } = info;
		return fetch(`${process.env.OMZ_ZAPI}/external/authentication/login`, {
			method: 'POST',
			headers: { 'Content-type': 'application/json' },
			body: JSON.stringify({ username, password })
		})
			.then(res => res.json())
			.then(async (json) => {
				if (json.status === 200) {
					const { token } = json;
					await validateAccessHours(token);
					sessionStorage.setItem('login', true);
					sessionStorage.setItem('omz_token', token);
					dispatch(authSuccess());
				} else {
					dispatch(authFailed());
				}
			}).catch(() => {
				dispatch(authFailed());
			});
	}
);

export const registerSuccess = () => ({
	type: actionTypes.REGISTER_AGENT_SUCCESS
});
export function registered() {
	return (dispatch) => {
		dispatch(registerSuccess());
	};
}

export const unRegisterBegin = () => ({
	type: actionTypes.UNREGISTER_AGENT_BEGIN
});
export function unRegister() {
	return (dispatch) => {
		dispatch(unRegisterBegin());
		window.omzVish.unregister();
	};
}

export const unRegisterSuccess = () => ({
	type: actionTypes.UNREGISTER_AGENT_SUCCESS
});
export function unRegistered() {
	return (dispatch) => {
		dispatch(unRegisterSuccess());
	};
}

export const setUnauthorized = () => ({
	type: actionTypes.SET_UNAUTHORIZED
});


export const registerPhoneBegin = () => ({
	type: actionTypes.REGISTER_PHONE_BEGIN
});
export const registerPhoneSuccess = () => ({
	type: actionTypes.REGISTER_PHONE_SUCCESS
});
export function registerPhone(data, hasActiveChat, onlyChatOrPhone) {
	return (dispatch) => {
		dispatch(registerPhoneBegin());
		dispatch(registerPhoneSuccess());
		const { name } = data;
		if (name === 'totalVoice') {
			const { station, token } = data;
			if (!hasActiveChat || !onlyChatOrPhone) window.ttvSdk.connect(token, station);
		} else {
			window.Inov8Sdk.connect();
		}
	};
}

export const unregisterPhone = (data, hasActiveChat, onlyChatOrPhone) => (
	(dispatch) => {
		dispatch({ type: actionTypes.UNREGISTER_PHONE });
		const { name } = data;
		if (name === 'totalVoice') {
			if (!hasActiveChat || !onlyChatOrPhone) window.ttvSdk.disconnect();
		} else {
			window.Inov8Sdk.disconnect();
		}
	}
);

export const fetchAgentDashboardBegin = () => ({
	type: actionTypes.FETCH_AGENT_DASHBOARD_BEGIN
});
export const fetchAgentDashboardSuccess = payload => ({
	type: actionTypes.FETCH_AGENT_DASHBOARD_SUCCESS,
	payload
});
export const fetchAgentDashboardFailed = () => ({
	type: actionTypes.FETCH_AGENT_DASHBOARD_FAILED
});

export const fetchAgentDashboard = () => (
	(dispatch) => {
		dispatch(fetchAgentDashboardBegin());
		return axios.get(`${process.env.OMZ_DASHBOARD}/uai/agent`, { dispatch })
			.then(({ data }) => {
				const {
					success,
					status,
					departments,
					voice,
					channels,
					// eslint-disable-next-line camelcase
					average_service_time
				} = data;
				if (status === 200 && success) {
					dispatch(fetchAgentDashboardSuccess({
						averageServiceTime:
						average_service_time,
						voice,
						channels,
						departments
					}));
				} else {
					dispatch(fetchAgentDashboardFailed());
				}
			}).catch(() => {
				dispatch(fetchAgentDashboardFailed());
			});
	}
);

export const fetchAgentContactsBegin = () => ({
	type: actionTypes.FETCH_AGENT_CONTACTS_BEGIN
});
export const fetchAgentContactsSuccess = customerInfo => ({
	type: actionTypes.FETCH_AGENT_CONTACTS_SUCCESS,
	payload: customerInfo
});
export const fetchAgentContactsFailed = () => ({
	type: actionTypes.FETCH_AGENT_CONTACTS_FAILED
});
export const fetchAgentContacts = requestInfo => (
	(dispatch) => {
		const { page = 1 } = requestInfo;
		dispatch(fetchAgentContactsBegin());
		return axios.get(`${process.env.OMZ_ZAPI}/admin/customers?limit=20&page=${page}`, { dispatch })
			.then(({ data }) => {
				const { customers, count, status } = data;
				const ended = customers && customers.length === 0;
				if (status === 200) {
					dispatch(fetchAgentContactsSuccess({
						customers,
						count,
						page,
						ended
					}));
				} else {
					dispatch(fetchAgentContactsFailed());
				}
			}).catch(() => {
				dispatch(fetchAgentContactsFailed());
			});
	}
);
export const fetchAgentContactsSearch = requestInfo => (
	(dispatch) => {
		const { search } = requestInfo;
		axios.get(`${process.env.OMZ_ZAPI}/admin/customers?limit=20&page=1&search=${search}`, { dispatch })
			.then(({ data }) => {
				const { customers, status } = data;
				if (status === 200) {
					const list = customers.map((item) => {
						let label = '';
						if (item && item.fields) {
							label = getCustomerFieldValue('main_identifier', item.fields);
						}

						return {
							label,
							value: item.id
						};
					});

					if (list.length > 0) {
						dispatch({
							type: actionTypes.FETCH_AGENTS_CONTACTS_SEARCH,
							payload: list
						});
					} else {
						dispatch({
							type: actionTypes.FETCH_AGENTS_CONTACTS_SEARCH,
							payload: []
						});
					}
				}
			}).catch(() => {});
	}
);

export const informExpiringSoonTasks = (inform, isSocket) => (
	dispatch => dispatch({
		type: actionTypes.INFORM_EXPIRING_SOON_TASKS,
		payload: { inform, isSocket }
	})
);

export const fetchAgentCountFailed = () => ({
	type: actionTypes.FETCH_AGENT_COUNT_FAILED
});
export const fetchAgentCountSuccess = count => ({
	type: actionTypes.FETCH_AGENT_COUNT_SUCCESS,
	payload: count
});
export const fetchTaskCount = () => (
	dispatch => axios.get(`${process.env.OMZ_ZAPI}/admin/tasks/count?timezone=${getTimezone()}`, { dispatch })
		.then(({ data }) => {
			const { count, status } = data;
			if (status === 200) {
				if (count > 0) dispatch(informExpiringSoonTasks(true, false));
				dispatch(fetchAgentCountSuccess(count));
			} else {
				dispatch(fetchAgentCountFailed());
			}
		}).catch(() => {
			dispatch(fetchAgentCountFailed());
		})
);

export const fetchAgentTasksBegin = (search, filter, status) => ({
	type: actionTypes.FETCH_AGENT_TASKS_BEGIN,
	payload: {
		search,
		filter,
		status
	}
});
export const fetchAgentTasksSuccess = requestInfo => ({
	type: actionTypes.FETCH_AGENT_TASKS_SUCCESS,
	payload: requestInfo
});
export const fetchAgentTasksFailed = () => ({
	type: actionTypes.FETCH_AGENT_TASKS_FAILED
});
export const fetchAgentTasks = requestInfo => (
	(dispatch) => {
		const {
			filter = '',
			status = '',
			page = 1,
			search = ''
		} = requestInfo;
		let url = `${process.env.OMZ_ZAPI}/admin/tasks?limit=20&page=${page}&timezone=${getTimezone()}`;

		if (search !== '') url = url.concat(`&search=${search}`);
		if (filter !== '') url = url.concat(`&filter=${filter}`);
		if (status !== '') url = url.concat(`&status=${status}`);

		dispatch(fetchAgentTasksBegin(search, filter, status));
		return axios.get(url, { dispatch })
			.then(({ data }) => {
				const { success, tasks, tasksNearToExpire } = data;

				if (success) {
					dispatch(fetchAgentTasksSuccess({
						tasks,
						page,
						filter,
						search,
						status,
						tasksNearToExpire
					}));
					return;
				}
				dispatch(fetchAgentTasksFailed());
			}).catch(() => {
				dispatch(fetchAgentTasksFailed());
			});
	}
);
export const fetchAgentTask = task => ({
	type: actionTypes.FETCH_AGENT_TASK,
	payload: task
});
export function createTaskSuccess() {
	return (dispatch) => {
		dispatch({
			type: actionTypes.CREATE_TASK_SUCCESS
		});
		dispatch(fetchAgentTasks({ filter: 'EXPIRED, OPENED' }));
	};
}
export const createOrUpdateInteractionTaskSuccess = () => ({
	type: actionTypes.CREATE_OR_UPDATE_INTERACTION_TASK_SUCCESS
});
export const createOrUpdateInteractionTaskBegin = () => ({
	type: actionTypes.CREATE_OR_UPDATE_INTERACTION_TASK_BEGIN
});
export const createOrUpdateInteractionTaskFailed = () => ({
	type: actionTypes.CREATE_OR_UPDATE_INTERACTION_TASK_FAILED
});
export const createOrUpdateInteractionTaskClear = () => (
	dispatch => dispatch({ type: actionTypes.CREATE_OR_UPDATE_INTERACTION_TASK_CLEAR })
);
export const createTask = ({ payload, inInteraction, createInteractionTask }) => (
	(dispatch) => {
		dispatch(createOrUpdateInteractionTaskBegin());
		const {
			name,
			description,
			status,
			customerId,
			expiresAt,
			interactionHash,
			extras,
			agentIds
		} = payload;

		const obj = {
			task: {
				name,
				description,
				status,
				customerId,
				expiresAt,
				extras,
				agentIds
			},
			interactionHash: [].concat(interactionHash || [])
		};

		const timezone = getTimezone();

		return fetch(`${process.env.OMZ_ZAPI}/admin/tasks?timezone=${timezone}`, {
			method: 'POST',
			headers: {
				Authorization: sessionStorage.getItem('omz_token'),
				'Content-type': 'application/json'
			},
			body: JSON.stringify(obj)
		})
			.then(res => res.json())
			.then((json) => {
				if (json.status === 200) {
					dispatch(fetchTaskCount());
					if (inInteraction) {
						createInteractionTask({
							payload: json.task,
							interactionHash
						});
						dispatch(createOrUpdateInteractionTaskSuccess());
					}
					dispatch(createTaskSuccess());
					dispatch(createOrUpdateInteractionTaskSuccess());
					dispatch(fetchTaskCount());
				} else {
					dispatch(createOrUpdateInteractionTaskFailed());
				}
			}).catch(() => {
				dispatch(createOrUpdateInteractionTaskFailed());
			});
	}
);

export const updateTask = ({ taskInfo, createInteractionTask }) => (
	(dispatch) => {
		dispatch(createOrUpdateInteractionTaskBegin());
		const {
			id,
			name,
			description,
			status,
			customerId,
			expiresAt,
			interactionHash,
			extras,
			agentIds
		} = taskInfo;

		const obj = {
			task: {
				name,
				description,
				status,
				customerId,
				expiresAt,
				agentIds
			},
			extras,
			interactionHash
		};

		const timezone = getTimezone();

		return fetch(`${process.env.OMZ_ZAPI}/admin/tasks/${id}?timezone=${timezone}`, {
			method: 'PUT',
			headers: {
				Authorization: sessionStorage.getItem('omz_token'),
				'Content-type': 'application/json'
			},
			body: JSON.stringify(obj)
		})
			.then(res => res.json())
			.then((json) => {
				if (json.status === 200) {
					if (createInteractionTask) {
						createInteractionTask({
							payload: json.task,
							interactionHash
						});
					}
					dispatch(createTaskSuccess());
					dispatch(createOrUpdateInteractionTaskSuccess());
					dispatch(fetchTaskCount());
				} else {
					dispatch(createOrUpdateInteractionTaskFailed());
				}
			}).catch(() => {
				dispatch(createOrUpdateInteractionTaskFailed());
			});
	});

export const resetPasswordBegin = () => ({
	type: actionTypes.RESET_PASSWORD_BEGIN
});
export const resetPasswordSuccess = () => ({
	type: actionTypes.RESET_PASSWORD_SUCCESS
});
export const resetPasswordFailed = () => ({
	type: actionTypes.RESET_PASSWORD_FAILED
});
export const resetPasswordClear = () => ({
	type: actionTypes.RESET_PASSWORD_CLEAR
});
export const resetPassword = email => (
	(dispatch) => {
		dispatch(resetPasswordBegin());
		return fetch(`${process.env.OMZ_ZAPI}/external/authentication/password`, {
			method: 'POST',
			headers: {
				Authorization: sessionStorage.getItem('omz_token'),
				'Content-type': 'application/json'
			},
			body: JSON.stringify({ email })
		})
			.then(res => res.json())
			.then((json) => {
				if (json.status === 200) {
					dispatch(resetPasswordSuccess());
				} else {
					dispatch(resetPasswordFailed());
				}
			}).catch(() => {
				dispatch(resetPasswordFailed());
			});
	}
);

export const clearNewTask = () => ({
	type: actionTypes.CLEAR_NEW_TASK
});
export const clearInteractionNewTask = () => ({
	type: actionTypes.CLEAR_INTERACTION_NEW_TASK
});
export const clearEditTask = () => ({
	type: actionTypes.CLEAR_EDIT_TASK
});

export const definePhoneConnectionData = type => (
	(dispatch) => {
		dispatch({
			type: actionTypes.DEFINE_PHONE_CONNECTION_DATA,
			payload: type
		});

		const decodedJson = decodeJWT(sessionStorage.getItem('omz_token'));
		const { profile, features } = decodedJson;
		const adminOnlineFeature = features.find(feature => (feature.name === 'admin_online'));
		const adminOnline = adminOnlineFeature && adminOnlineFeature.status === 'true';

		const agentCustomOnlineFeature = features.find(feature => (feature.name === 'agent_custom_online'));
		const agentCustomOnline = agentCustomOnlineFeature && agentCustomOnlineFeature.status === 'true';

		const agentOfflineFeature = features.find(feature => (feature.name === 'agent_offline'));
		const agentOffline = agentOfflineFeature && agentOfflineFeature.status === 'true';

		if ((!['ADMIN', 'AGENT'].includes(profile) && !agentCustomOnline) || (profile === 'ADMIN' && !adminOnline) || (profile === 'AGENT' && agentOffline) || (profile === 'ACCESS_SUPPORT')) return;

		navigator.mediaDevices.getUserMedia({ audio: true }).then((media) => {
			if (media.active) dispatch(registerPhone(type, false));
		}).catch(() => {
			dispatch(addNotification({
				title: locale['navBar.disabledMic'],
				content: locale['navBar.allowMic'],
				type: 'error',
				automaticClose: false
			}));
		});
	}
);

export const updateCallStatus = status => (
	dispatch => dispatch({
		type: actionTypes.UPDATE_CALL_STATUS,
		payload: status
	})
);

export const updateConferenceUsers = user => (
	dispatch => dispatch({
		type: actionTypes.UPDATE_CONFERENCE_USERS,
		payload: user
	})
);

export const clearConferenceUsers = () => (
	dispatch => dispatch({
		type: actionTypes.CLEAR_CONFERENCE_USERS
	})
);

export const searchAgentContactsBegin = () => ({
	type: actionTypes.SEARCH_AGENT_CONTACTS_BEGIN
});
export const searchAgentContactsSuccess = (customers, page, ended) => ({
	type: actionTypes.SEARCH_AGENT_CONTACTS_SUCCESS,
	payload: { customers, page, ended }
});
export const searchAgentContactsFailed = () => ({
	type: actionTypes.SEARCH_AGENT_CONTACTS_FAILED
});
export const searchAgentContacts = requestInfo => (
	(dispatch) => {
		const { search, page = 1 } = requestInfo;
		dispatch(searchAgentContactsBegin());
		return axios.get(`${process.env.OMZ_ZAPI}/admin/customers?limit=20&page=${page}&search=${search}`, { dispatch })
			.then(({ data }) => {
				const { customers, status } = data;
				const ended = customers.length === 0;
				if (status === 200) {
					dispatch(searchAgentContactsSuccess(customers, page, ended));
				} else {
					dispatch(searchAgentContactsFailed());
				}
			}).catch(() => {
				dispatch(searchAgentContactsFailed());
			});
	}
);

export const updateCreatingInteraction = info => (
	dispatch => dispatch({
		type: actionTypes.CREATING_INTERACTION,
		payload: info
	})
);

export const addCustomerBegin = () => ({
	type: actionTypes.ADD_CUSTOMER_BEGIN
});
export const addCustomerSuccess = () => ({
	type: actionTypes.ADD_CUSTOMER_SUCCESS
});
export const addCustomerFailed = errors => ({
	type: actionTypes.ADD_CUSTOMER_FAILED,
	payload: errors
});
export const addCustomerClear = () => (
	dispatch => dispatch({ type: actionTypes.ADD_CUSTOMER_CLEAR })
);
export const addCustomer = info => (
	(dispatch) => {
		dispatch(addCustomerBegin());
		const { fields } = info;
		return fetch(`${process.env.OMZ_ZAPI}/admin/customers`, {
			method: 'POST',
			headers: {
				Authorization: sessionStorage.getItem('omz_token'),
				'Content-type': 'application/json'
			},
			body: JSON.stringify({ fields })
		})
			.then(res => res.json())
			.then((json) => {
				if (json.status === 200) {
					dispatch(fetchAgentContacts({ page: 1 }));
					dispatch(addCustomerSuccess());
				} else if (json.status === 422) {
					if (json.status === 422) dispatch(addCustomerFailed(json.errors));
				}
			}).catch(() => {});
	}
);

export const finishTask = taskId => (
	dispatch => dispatch({
		type: actionTypes.FINISH_TASK,
		payload: taskId
	})
);

export const updatePhotoBegin = () => ({ type: actionTypes.UPDATE_PHOTO_BEGIN });
export const updatePhotoFailed = () => ({ type: actionTypes.UPDATE_PHOTO_FAILED });
export const updatePhotoSuccess = photo => ({
	type: actionTypes.UPDATE_PHOTO_SUCCESS,
	payload: photo
});
export const updatePhotoClear = () => dispatch => dispatch({
	type: actionTypes.UPDATE_PHOTO_CLEAR
});
export const updatePhoto = requestInfo => (dispatch) => {
	const { photo, agentId } = requestInfo;
	dispatch(updatePhotoBegin());
	return fetch(`${process.env.OMZ_ZAPI}/admin/agents/${agentId}`, {
		method: 'PUT',
		headers: {
			Authorization: sessionStorage.getItem('omz_token'),
			'Content-type': 'application/json'
		},
		body: JSON.stringify({ photo })
	}).then((response) => {
		if (response.status !== 200) return dispatch(updatePhotoFailed());
		return response.json();
	}).then((json) => {
		if (json.agent.photo) return dispatch(updatePhotoSuccess(json.agent.photo));
		return dispatch(updatePhotoFailed());
	}).catch(() => dispatch(updatePhotoFailed()));
};

export const updateSettingsBegin = () => ({ type: actionTypes.UPDATE_SETTINGS_BEGIN });
export const updateSettingsFailed = () => ({ type: actionTypes.UPDATE_SETTINGS_FAILED });
export const updateSettingsSuccess = settings => ({
	type: actionTypes.UPDATE_SETTINGS_SUCCESS,
	payload: settings
});
export const updateSettings = requestInfo => (dispatch) => {
	const { settings, agentId } = requestInfo;
	dispatch(updateSettingsBegin());
	return fetch(`${process.env.OMZ_ZAPI}/admin/agents/${agentId}`, {
		method: 'PUT',
		headers: {
			Authorization: sessionStorage.getItem('omz_token'),
			'Content-type': 'application/json'
		},
		body: JSON.stringify({ settings })
	}).then((response) => {
		if (response.status !== 200) return dispatch(updateSettingsFailed());
		return response.json();
	}).then((json) => {
		dispatch(refreshToken());
		if (json.agent.settings) return dispatch(updateSettingsSuccess(json.agent.settings));
		return dispatch(updateSettingsFailed());
	}).catch(() => dispatch(updateSettingsFailed()));
};

export const updatePasswordBegin = () => ({ type: actionTypes.UPDATE_PASSWORD_BEGIN });
export const updatePasswordFailed = error => ({
	type: actionTypes.UPDATE_PASSWORD_FAILED,
	payload: error
});
export const updatePasswordClear = () => dispatch => dispatch({
	type: actionTypes.UPDATE_PASSWORD_CLEAR
});
export const updatePasswordSuccess = () => ({ type: actionTypes.UPDATE_PASSWORD_SUCCESS });
export const updatePassword = requestInfo => (dispatch) => {
	const {
		oldPassword,
		password,
		agentId,
		auth0UserID,
		email
	} = requestInfo;
	const auth0Enabled = isAuth0Enabled() && isZenviaDomain();

	dispatch(updatePasswordBegin());

	const messageLocale = localStorage.getItem('locale') || 'pt-BR';

	return fetch(`${process.env.OMZ_ZAPI}/admin/agents/${agentId}?locale=${messageLocale}`, {
		method: 'PUT',
		headers: {
			Authorization: sessionStorage.getItem('omz_token'),
			'Content-type': 'application/json'
		},
		body: JSON.stringify({
			email,
			old_password: (!auth0Enabled) ? oldPassword : null,
			password,
			user_id: auth0UserID,
			auth0Enabled
		})
	}).then((response) => {
		if (response.status !== 200) return dispatch(updatePasswordFailed());
		return response.json();
	}).then((json) => {
		const error = json.errors || json.error;
		if (error && json.status) return dispatch(updatePasswordFailed(error));
		return dispatch(updatePasswordSuccess());
	}).catch(() => dispatch(updatePasswordFailed()));
};

export const fetchWhatsAppTemplatesBegin = () => ({
	type: actionTypes.FETCH_WHATSAPP_TEMPLATES_BEGIN
});
export const fetchWhatsAppTemplatesSuccess = templates => ({
	type: actionTypes.FETCH_WHATSAPP_TEMPLATES_SUCCESS,
	payload: { templates }
});
export const fetchWhatsAppTemplatesFailed = () => ({
	type: actionTypes.FETCH_WHATSAPP_TEMPLATES_FAILED
});
export const fetchWhatsAppTemplates = info => (
	(dispatch) => {
		const { senderId, search = '' } = info;

		dispatch(fetchWhatsAppTemplatesBegin());

		return fetch(`${process.env.OMZ_ZAPI}/admin/accounts/templates?senderId=${senderId}&name=${search}`, {
			method: 'GET',
			headers: {
				Authorization: sessionStorage.getItem('omz_token'),
				'Content-type': 'application/json'
			}
		})
			.then(res => res.json())
			.then(({ status, result, message }) => {
				if (status === 200 && message === 'OK') {
					dispatch(fetchWhatsAppTemplatesSuccess(result));
				} else {
					dispatch(fetchWhatsAppTemplatesFailed());
				}
			}).catch(() => {
				dispatch(fetchWhatsAppTemplatesFailed());
			});
	}
);

export const updateUserID = userID => ({
	type: actionTypes.UPDATE_USERID,
	payload: userID
});
