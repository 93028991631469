import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';

import './index.sass';
import HistoryItem from '../HistoryItem';
import Text from '../../Atoms/Text';
import ImgSvg from '../../Atoms/ImgSvg';
import Spinner from '../../Atoms/Spinner';
import InfiniteList from '../InfiniteList';

const messagesIntl = defineMessages({
	conversations: {
		id: 'historyList.conversations',
		defaultMessage: '{itemCount, plural, one {atendimento} other {atendimentos}}'
	},
	empty: {
		id: 'historyList.empty',
		defaultMessage: 'Sem conversas no histórico'
	},
	emptyDescription: {
		id: 'historyList.emptyDescription',
		defaultMessage: 'Este cliente não possui registro de outras interações'
	}
});

const HistoryList = ({
	onSelectHistory,
	loading,
	customer,
	showHistoryMessages,
	intl,
	loadMoreHistories
}) => (
	<div className="HistoryList">
		<div>
			{customer.history && customer.history.length === 0 && !loading && (
				<div className="InteractionList__empty">
					<div className="InteractionList__empty__image">
						<ImgSvg name="empty" />
					</div>
					<span className="InteractionList__empty__info">
						<Text>{intl.formatMessage(messagesIntl.empty)}</Text>
						<Text size="bigger">{intl.formatMessage(messagesIntl.emptyDescription)}</Text>
					</span>
				</div>
			)}
		</div>
		<div className="HistoryList__wrapper">
			<InfiniteList
				customClass="HistoryList__list"
				onBottom={() => {
					loadMoreHistories();
					onSelectHistory('');
				}}
			>
				{customer.history && customer.history.map(historyItem => (
					<HistoryItem
						key={historyItem.id}
						historyInfo={historyItem}
						onSelectHistory={onSelectHistory}
						showHistoryMessages={showHistoryMessages}
					/>
				))}
				{loading && (<Spinner />)}
			</InfiniteList>
		</div>
	</div>
);

HistoryList.propTypes = {
	onSelectHistory: PropTypes.func.isRequired,
	loading: PropTypes.bool,
	customer: PropTypes.shape({
		history: PropTypes.arrayOf(PropTypes.shape({}))
	}).isRequired,
	showHistoryMessages: PropTypes.string.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	loadMoreHistories: PropTypes.func.isRequired
};

export default injectIntl(HistoryList);
