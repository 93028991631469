import React from 'react';
import { MESSAGE_CONTENT_TYPE } from '../../../../shared/consts';

import MessageAttachment from '../MessageAttachment';
import MessageText from '../MessageText';
import MessageState from '../MessageState';
import MessageImage from '../MessageImage';
import MessageAudio from '../MessageAudio';
import MessageVideo from '../MessageVideo';
import MessageInternal from '../MessageInternal';
import ReferencedMessage from '../ReferencedMessage';

const getVerifiedContentType = (contentType) => {
	let result = contentType;

	if (result.match(MESSAGE_CONTENT_TYPE.IMAGE)) {
		result = MESSAGE_CONTENT_TYPE.IMAGE;
	} else if (result.match(MESSAGE_CONTENT_TYPE.AUDIO)) {
		result = MESSAGE_CONTENT_TYPE.AUDIO;
	} else if (result.match(MESSAGE_CONTENT_TYPE.VIDEO)) {
		result = MESSAGE_CONTENT_TYPE.VIDEO;
	}

	return result;
};

const getComponentByType = (messageInfo, children = null) => {
	const verifiedContentType = getVerifiedContentType(messageInfo.contentType);
	const props = {
		messageInfo,
		children
	};

	if (messageInfo.isInternalHistory) {
		return <MessageText {...props}><div><b>{messageInfo.agentName}</b></div></MessageText>;
	}

	if (messageInfo.isFromInternalAdmin || messageInfo.isFromInternalAgent) {
		return <MessageInternal {...props} />;
	}

	switch (verifiedContentType) {
	case MESSAGE_CONTENT_TYPE.TEXT:
	case MESSAGE_CONTENT_TYPE.EMAIL:
		return <MessageText {...props} />;
	case MESSAGE_CONTENT_TYPE.STATE:
		return <MessageState {...props} />;
	case MESSAGE_CONTENT_TYPE.IMAGE:
		return <MessageImage {...props} />;
	case MESSAGE_CONTENT_TYPE.AUDIO:
		return <MessageAudio {...props} />;
	case MESSAGE_CONTENT_TYPE.VIDEO:
		return <MessageVideo {...props} />;
	default:
		return <MessageAttachment {...props} />;
	}
};

const getReferencedMessageWrapped = messageInfo => (
	<ReferencedMessage messageInfo={messageInfo}>
		{getComponentByType(messageInfo)}
	</ReferencedMessage>
);

const getMessageComponent = (messageInfo) => {
	// Obtain referenced message if it exists.
	const hasReferencedMessage = messageInfo && messageInfo.referencedMessage && !messageInfo.isInternalHistory;
	const referencedMessageComponent = (
		hasReferencedMessage ? getReferencedMessageWrapped(messageInfo.referencedMessage) : null
	);

	return getComponentByType(messageInfo, referencedMessageComponent);
};

export default getMessageComponent;
