import {
	ACTIVE_INTERACTION_STATES,
	ENDED_INTERACTION_STATES,
	INBOX_INTERACTION_STATES,
	MISSED_INTERACTION_STATES,
	QUEUE_INTERACTION_STATES
} from '../../shared/consts';
import {
	filterArray,
	getInteractionsCountDiff,
	manageInterectionStatus,
	mapNewInteractionItem,
	mapNewMessageItem,
	setRequestStatus,
	updateObject,
	verifyIfHasActiveChat,
	mapInternalMessageIfNecessary,
	messageAlreadyExists
} from '../../shared/utility';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
	interactions: [],
	pendingInteractions: [],
	missedInteractions: [],
	filteredInteractions: [],
	filteredPendingInteractions: [],
	filteredMissedInteractions: [],
	currentInteractionHash: '',
	currentInteraction: {},
	count: {
		queue: 0,
		inbox: 0,
		missed: 0
	},
	transferData: {
		agents: [],
		departments: []
	},
	updateNoteStatus: [],
	updateTagsStatus: [],
	fetchTransferDataStatus: {},
	updateInteractionCustomerStatus: [],
	fetchInteractionHistoryStatus: [],
	fetchCustomerStatus: [],
	updateCustomerStatus: [],
	fetchHistoryStatus: [],
	fetchInteractionInfoStatus: [],
	historyInteractions: [],
	hasActiveChat: false,
	onlyChatOrPhone: true,
	sendAttachmentEmailStatus: [],
	createdOrUpdatedTask: false,
	sentMessages: [],
	newWhatsappVoiceCall: {},
	newWhatsappMediaStream: {},
	terminateWhatsappVoiceCall: {},
	whatsappCallDuration: {},
	callDuration: 0
};

const fetchInteractionsCount = (state, action) => {
	const { queue, inbox, missed } = action.payload;

	return updateObject(state, {
		count: { queue, inbox, missed },
		fetchInteractionsCountStatus: setRequestStatus('success')
	});
};

const onNewInteraction = (state, action) => {
	const newInteraction = mapNewInteractionItem(action.payload);
	const { currentState } = newInteraction;
	let existInteraction = false;
	let interactions = state.interactions.map((interaction) => {
		if (interaction.interactionHash === newInteraction.interactionHash) {
			existInteraction = true;
			return {
				...interaction,
				currentState: newInteraction.currentState
			};
		}

		return interaction;
	});

	if (!existInteraction) {
		interactions = [
			...interactions,
			{
				...newInteraction,
				currentState: currentState !== 'TALKING' && currentState !== 'REPLYING' ? 'RINGING' : currentState
			}
		];
	}

	const hasActiveChat = verifyIfHasActiveChat(interactions);
	setTimeout(() => {
		if (hasActiveChat && window.ttvSdk.connected && state.onlyChatOrPhone) {
			window.ttvSdk.disconnect();
		}
	}, 200);

	return updateObject(state, {
		interactions,
		hasActiveChat,
		fetchInteractionInfoStatus: state.fetchInteractionInfoStatus.filter(
			({ hash }) => hash !== newInteraction.interactionHash
		),
		count: getInteractionsCountDiff(state, interactions)
	});
};

const onAcceptedInteraction = (state, action) => {
	const interactionHash = action.payload;
	const interactions = state.interactions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) {
			return {
				...interaction,
				currentState: 'TALKING'
			};
		}

		return interaction;
	});

	const filteredInteractions = state.filteredInteractions.filter(interaction => interaction.interactionHash !== interactionHash);
	const filteredPendingInteractions = state.filteredPendingInteractions.filter(interaction => interaction.interactionHash !== interactionHash);
	const filteredMissedInteractions = state.filteredMissedInteractions.filter(interaction => interaction.interactionHash !== interactionHash);

	return updateObject(state, {
		interactions,
		filteredInteractions,
		filteredPendingInteractions,
		filteredMissedInteractions
	});
};

const onRepliedInteraction = (state, action) => {
	const interactionHash = action.payload;
	const interactions = state.interactions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) {
			return {
				...interaction,
				currentState: 'REPLYING'
			};
		}

		return interaction;
	});

	return updateObject(state, {
		interactions,
		currentInteractionHash: interactionHash,
		count: getInteractionsCountDiff(state, interactions)
	});
};

const clearSelectedInteraction = state => (updateObject(state, { currentInteractionHash: '' }));

const defineSelectedInteraction = (state, action) => {
	const interactionHash = action.payload;
	clearSelectedInteraction();
	return updateObject(state, {
		currentInteractionHash: interactionHash
	});
};

const setCurrentInteraction = (state, action) => updateObject(state, {
	currentInteraction: action.payload
});

const acceptInteractionBegin = (state, action) => {
	const interactionHash = action.payload;
	const interactions = state.interactions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) {
			return {
				...interaction,
				currentState: 'ACCEPTING'
			};
		}

		return interaction;
	});

	return updateObject(state, {
		interactions,
		count: getInteractionsCountDiff(state, interactions)
	});
};

const setPendingMessages = (state, action) => {
	const { payload } = action;
	if (payload !== state.currentInteractionHash) {
		const interactions = state.interactions.map((interaction) => {
			const { pendingMessages, interactionHash } = interaction;
			if (interactionHash === payload) {
				return {
					...interaction,
					pendingMessages: Number(pendingMessages) + 1 || 1
				};
			}

			return interaction;
		});

		const pendingInteractions = state.pendingInteractions.map((interaction) => {
			const { pendingMessages, interactionHash } = interaction;
			if (interactionHash === payload) {
				return {
					...interaction,
					pendingMessages: Number(pendingMessages) + 1 || 1
				};
			}

			return interaction;
		});

		return updateObject(state, {
			interactions,
			pendingInteractions
		});
	}

	return state;
};

const onUpdateInteraction = (state, action) => {
	const { interaction, agent } = action.payload;
	let {
		interactions, pendingInteractions, missedInteractions, currentInteraction
	} = state;
	const mappedInteraction = mapNewInteractionItem(interaction);
	const isActiveInteraction = ACTIVE_INTERACTION_STATES.includes(mappedInteraction.currentState);
	const isInboxInteraction = INBOX_INTERACTION_STATES.includes(mappedInteraction.currentState);
	const isQueueInteraction = QUEUE_INTERACTION_STATES.includes(mappedInteraction.currentState);
	const isEndedInteraction = ENDED_INTERACTION_STATES.includes(mappedInteraction.currentState);
	const isMissedInteraction = MISSED_INTERACTION_STATES.includes(mappedInteraction.currentState);
	const fetchInteractionInfoStatus = filterArray(state.fetchInteractionInfoStatus, 'hash', mappedInteraction.interactionHash);
	let currentPendingInteraction = {};
	let existInteraction = false;
	let previousMessages = null;

	const findInteractionAndDiscard = (list) => {
		const finded = list.find(item => item.interactionHash === mappedInteraction.interactionHash);
		if (finded) {
			finded.rejected = true;
			finded.currentState = 'DISCARDED';
		}

		return finded;
	};

	const mapInteractions = (list) => {
		const {
			interactionHash, slas, message
		} = mappedInteraction;

		return list.map((listItem) => {
			if (listItem.interactionHash === interactionHash) {
				previousMessages = listItem.messages;
				currentPendingInteraction = listItem;

				return {
					...listItem,
					slas,
					...(mappedInteraction.message && {
						messages: mapInternalMessageIfNecessary(listItem.messages, message)
					}),
					...(message && !messageAlreadyExists(listItem.messages, message) && {
						pendingMessages: Number(currentPendingInteraction.pendingMessages) + 1 || 1
					})
				};
			}

			return listItem;
		});
	};

	const updateInteractionList = list => list.map((listItem) => {
		if (listItem.interactionHash === mappedInteraction.interactionHash) {
			existInteraction = true;

			return {
				...listItem,
				currentState: mappedInteraction.currentState,
				...(mappedInteraction.message && {
					messages: mapInternalMessageIfNecessary(listItem.messages, mappedInteraction.message)
				})
			};
		}

		return listItem;
	});

	interactions = mapInteractions(interactions);
	pendingInteractions = mapInteractions(pendingInteractions);
	missedInteractions = mapInteractions(missedInteractions);

	if (!mappedInteraction.agent || mappedInteraction.agent.id === agent.info.id) {
		if (isInboxInteraction) {
			if (!isEndedInteraction) interactions = filterArray(interactions, 'interactionHash', mappedInteraction.interactionHash);
			pendingInteractions = updateInteractionList(pendingInteractions);
			interactions = updateInteractionList(interactions);

			if (!existInteraction && !isEndedInteraction) pendingInteractions.push({ ...mappedInteraction, ...(previousMessages && { messages: previousMessages }) });
		}

		if (isQueueInteraction || isActiveInteraction) {
			pendingInteractions = filterArray(pendingInteractions, 'interactionHash', mappedInteraction.interactionHash);
			missedInteractions = filterArray(missedInteractions, 'interactionHash', mappedInteraction.interactionHash);
			interactions = updateInteractionList(interactions);

			if (existInteraction && !mappedInteraction.agent && isActiveInteraction) {
				interactions = filterArray(interactions, 'interactionHash', mappedInteraction.interactionHash);
			}

			if (!existInteraction && !isEndedInteraction) {
				mappedInteraction.messages = previousMessages || [];
				interactions.push({ ...currentPendingInteraction, ...mappedInteraction });
			}
		}

		if (isMissedInteraction) {
			interactions = filterArray(interactions, 'interactionHash', mappedInteraction.interactionHash);
			missedInteractions = updateInteractionList(missedInteractions);

			if (!existInteraction) missedInteractions.push(mappedInteraction);
		}
	} else {
		interactions = filterArray(interactions, 'interactionHash', mappedInteraction.interactionHash);
		pendingInteractions = filterArray(pendingInteractions, 'interactionHash', mappedInteraction.interactionHash);
		missedInteractions = filterArray(missedInteractions, 'interactionHash', mappedInteraction.interactionHash);
	}

	const hasActiveChat = verifyIfHasActiveChat(interactions);
	const { phoneData, registeredPhone } = agent;
	if (hasActiveChat && registeredPhone) {
		if (phoneData.name === 'totalVoice' && window.ttvSdk.connected && state.onlyChatOrPhone) window.ttvSdk.disconnect();
	}

	if (mappedInteraction.currentState === 'ON_HOLD') {
		interactions = filterArray(state.interactions, 'interactionHash', mappedInteraction.interactionHash);
		pendingInteractions = filterArray(state.pendingInteractions, 'interactionHash', mappedInteraction.interactionHash);
	}

	if (mappedInteraction.currentState === 'DISCARDED') {
		if (mappedInteraction.cause === 'templateRejected' && mappedInteraction.agentId === agent.info.id) {
			const finded = findInteractionAndDiscard(pendingInteractions);
			if (finded) interactions.push(finded);
			else findInteractionAndDiscard(interactions);
		} else {
			interactions = filterArray(state.interactions, 'interactionHash', mappedInteraction.interactionHash);
		}

		pendingInteractions = filterArray(state.pendingInteractions, 'interactionHash', mappedInteraction.interactionHash);
		missedInteractions = filterArray(missedInteractions, 'interactionHash', mappedInteraction.interactionHash);
	}

	if (currentInteraction.interactionHash === mappedInteraction.interactionHash) {
		currentInteraction = {
			...currentInteraction,
			...(mappedInteraction.message && (mappedInteraction.message.source === 'INT_ADMIN' || mappedInteraction.message.source === 'INT_AGENT') && {
				messages: mapInternalMessageIfNecessary(currentInteraction.messages, mappedInteraction.message)
			})
		};
	}

	return updateObject(state, {
		interactions,
		currentInteraction,
		pendingInteractions,
		missedInteractions,
		fetchInteractionInfoStatus,
		hasActiveChat,
		count: getInteractionsCountDiff(state, interactions, pendingInteractions, missedInteractions)
	});
};

const onLoadInteractionInfoBegin = (state, action) => {
	const interactionHash = action.payload;

	return updateObject(state, {
		fetchInteractionInfoStatus: manageInterectionStatus(state, null, 'fetchInteractionInfoStatus', interactionHash)
	});
};


const onLoadInteractionInfoFailed = (state, action) => {
	const interactionHash = action.payload;

	return updateObject(state, {
		fetchInteractionInfoStatus: manageInterectionStatus(state, 'failed', 'fetchInteractionInfoStatus', interactionHash)
	});
};

const onLoadInteractionInfoSuccess = (state, action) => {
	const { interactionHash } = action.payload;
	const { interactionInfo } = action.payload;
	const {
		note,
		tags,
		tasks,
		senderId,
		whatsappId,
		source,
		customer
	} = interactionInfo;

	// eslint-disable-next-line camelcase
	const { parsed_messages } = interactionInfo;
	const interactions = state.interactions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) {
			const { interactionType } = interaction;
			let messages = [];
			if (interactionType !== 'PHONE') {
				messages = parsed_messages.map(message => (
					mapNewMessageItem(message)
				));
			}

			return {
				...interaction,
				customerInfo: customer,
				messages,
				note,
				interactionTags: tags,
				tasks,
				senderId,
				whatsappId,
				source
			};
		}

		return interaction;
	});

	const pendingInteractions = state.pendingInteractions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) {
			const { interactionType } = interaction;
			let messages = [];
			if (interactionType !== 'PHONE') {
				messages = parsed_messages.map(message => (
					mapNewMessageItem(message)
				));
			}

			return {
				...interaction,
				customerInfo: customer,
				messages,
				note,
				interactionTags: tags,
				tasks,
				senderId,
				whatsappId,
				source
			};
		}

		return interaction;
	});

	const missedInteractions = state.missedInteractions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) {
			const { interactionType } = interaction;
			let messages = [];
			if (interactionType !== 'PHONE') {
				messages = parsed_messages.map(message => (
					mapNewMessageItem(message)
				));
			}

			return {
				...interaction,
				customerInfo: customer,
				messages,
				note,
				interactionTags: tags,
				tasks,
				senderId,
				whatsappId,
				source
			};
		}

		return interaction;
	});

	const historyInteractions = state.historyInteractions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) {
			// eslint-disable-next-line camelcase
			const messages = parsed_messages ? parsed_messages.map(message => (
				mapNewMessageItem(message)
			)) : [];

			const endedPosition = messages.findIndex(message => message.status === 'ENDED');

			if (endedPosition) {
				messages.push(messages.splice(endedPosition, 1)[0]);
			}

			return {
				...interaction,
				customerInfo: customer,
				messages,
				note,
				interactionTags: tags,
				tasks,
				senderId,
				whatsappId,
				source
			};
		}

		return interaction;
	});

	const currentInteraction = {
		interactionHash,
		customerInfo: customer,
		// eslint-disable-next-line camelcase
		messages: parsed_messages ? parsed_messages.map(message => (
			mapNewMessageItem(message)
		)) : [],
		note,
		interactionTags: tags,
		tasks,
		senderId,
		whatsappId,
		source
	};

	return updateObject(state, {
		interactions,
		currentInteraction,
		pendingInteractions,
		historyInteractions,
		missedInteractions,
		fetchInteractionInfoStatus: manageInterectionStatus(state, 'success', 'fetchInteractionInfoStatus', interactionHash)
	});
};

const clearPendingMessages = (state, action) => {
	const { payload } = action;
	const interactions = state.interactions.map((interaction) => {
		const { interactionHash } = interaction;
		if (interactionHash === payload) {
			return {
				...interaction,
				pendingMessages: null
			};
		}

		return interaction;
	});

	const pendingInteractions = state.pendingInteractions.map((interaction) => {
		const { interactionHash } = interaction;
		if (interactionHash === payload) {
			return {
				...interaction,
				pendingMessages: ''
			};
		}

		return interaction;
	});

	return updateObject(state, {
		interactions,
		pendingInteractions
	});
};

const onNewMessage = (state, action) => {
	const { interactionHash, newMessage } = action.payload;
	const interactions = state.interactions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) {
			return {
				...interaction,
				blocked: false,
				messages: messageAlreadyExists(interaction.messages, newMessage) ? interaction.messages : [
					...interaction.messages,
					newMessage
				]
			};
		}

		return interaction;
	});

	const pendingInteractions = state.pendingInteractions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) {
			return {
				...interaction,
				messages: messageAlreadyExists(interaction.messages, newMessage) ? interaction.messages : [
					...interaction.messages,
					newMessage
				]
			};
		}

		return interaction;
	});
	return updateObject(state, { interactions, pendingInteractions });
};

const onSendMessage = (state, action) => {
	const { interactionHash, receivedMessage } = action.payload;
	// FIXME: Duplicated code
	const interactions = state.interactions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) {
			let existMessage = false;
			const parsedInteractionMessages = interaction.messages.map((message) => {
				if (receivedMessage.tempId === message.tempId) {
					existMessage = true;
					return receivedMessage;
				}

				return message;
			});

			if (!existMessage) parsedInteractionMessages.push(receivedMessage);

			return {
				...interaction,
				messages: parsedInteractionMessages
			};
		}

		return interaction;
	});

	const pendingInteractions = state.pendingInteractions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) {
			let existMessage = false;
			const parsedInteractionMessages = interaction.messages.map((message) => {
				if (receivedMessage.tempId === message.tempId) {
					existMessage = true;
					return receivedMessage;
				}

				return message;
			});

			if (!existMessage) parsedInteractionMessages.push(receivedMessage);

			return {
				...interaction,
				messages: parsedInteractionMessages
			};
		}

		return interaction;
	});

	return updateObject(state, {
		interactions,
		pendingInteractions,
		sentMessages: [
			...state.sentMessages,
			{ message: receivedMessage, interactionHash }
		]
	});
};

const onNewWhatsappVoiceCall = (state, action) => updateObject(state, { newWhatsappVoiceCall: action.payload });

const onNewWhatsappMediaStream = (state, action) => updateObject(state, { newWhatsappMediaStream: action.payload });

const onTerminateWhatsappVoiceCallSuccess = (state, action) => updateObject(state, { terminateWhatsappVoiceCall: action.payload });

const onWhatsappCallDuration = (state, action) => updateObject(state, { whatsappCallDuration: action.payload });

const updateCallDuration = (state, action) => updateObject(state, { callDuration: action.payload });

const onReceivedMessage = (state, action) => {
	const { interactionHash, tempId, messageId } = action.payload;
	// FIXME: Duplicated code
	const interactions = state.interactions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) {
			return {
				...interaction,
				messages: interaction.messages.map((message) => {
					if (message.tempId === tempId) {
						return {
							...message,
							status: 'received',
							messageId
						};
					}

					return message;
				})
			};
		}

		return interaction;
	});

	const pendingInteractions = state.pendingInteractions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) {
			return {
				...interaction,
				messages: interaction.messages.map((message) => {
					if (message.tempId === tempId) {
						return {
							...message,
							status: 'received',
							messageId
						};
					}

					return message;
				})
			};
		}

		return interaction;
	});

	return updateObject(state, {
		interactions,
		pendingInteractions,
		sentMessages: state.sentMessages.filter(sentMessage => (
			sentMessage.interactionHash !== interactionHash && sentMessage.message.tempId !== tempId
		))
	});
};

const onDeliveredMessage = (state, action) => {
	const { interactionHash, messageId } = action.payload;
	// FIXME: Duplicated code
	const interactions = state.interactions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) {
			return {
				...interaction,
				messages: interaction.messages.map((message) => {
					if (message.messageId === messageId) {
						return {
							...message,
							status: 'delivered'
						};
					}

					return message;
				})
			};
		}

		return interaction;
	});

	const pendingInteractions = state.pendingInteractions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) {
			return {
				...interaction,
				slas: interaction.slas ? interaction.slas.map((sla) => {
					if (sla.slaName === 'sla_first_reply_time' && !sla.slaEndedAt) {
						// eslint-disable-next-line no-param-reassign
						sla.slaEndedAt = new Date();
					}
					return sla;
				}) : [],
				messages: interaction.messages.map((message) => {
					if (message.messageId === messageId) {
						return {
							...message,
							status: 'delivered'
						};
					}

					return message;
				})
			};
		}

		return interaction;
	});

	return updateObject(state, { interactions, pendingInteractions });
};

const onTyping = (state, action) => {
	const interactionHash = action.payload;
	// FIXME: Duplicated code
	const interactions = state.interactions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) {
			return {
				...interaction,
				typing: true
			};
		}

		return interaction;
	});

	const pendingInteractions = state.pendingInteractions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) {
			return {
				...interaction,
				typing: true
			};
		}

		return interaction;
	});

	return updateObject(state, { interactions, pendingInteractions });
};

const onCleared = (state, action) => {
	const interactionHash = action.payload;
	// FIXME: Duplicated code
	const interactions = state.interactions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) {
			return {
				...interaction,
				typing: false
			};
		}

		return interaction;
	});

	const pendingInteractions = state.pendingInteractions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) {
			return {
				...interaction,
				typing: false
			};
		}

		return interaction;
	});

	return updateObject(state, { interactions, pendingInteractions });
};

const onTransferred = (state, action) => {
	const interactionHash = action.payload;
	// FIXME: Duplicated code
	const interactions = state.interactions.filter(interaction => (
		interaction.interactionHash !== interactionHash || (interaction.interactionHash === interactionHash && interaction.currentState !== 'TALKING')
	));
	const pendingInteractions = state.pendingInteractions.filter(interaction => (
		interaction.interactionHash !== interactionHash
	));
	const fetchInteractionInfoStatus = state.fetchInteractionInfoStatus.filter(
		interaction => interaction.hash !== interactionHash
	);

	return updateObject(state, {
		interactions,
		pendingInteractions,
		currentInteractionHash: '',
		fetchInteractionInfoStatus,
		count: getInteractionsCountDiff(state, interactions, pendingInteractions)
	});
};

const onFinishing = (state, action) => {
	const { interactionHash, removeThisInteraction, agentName } = action.payload;
	const stateMessage = {
		agentName,
		expired: false,
		state: 'ENDED',
		time: new Date(),
		type: 'state'
	};
	// FIXME: Duplicated code
	let interactions = state.interactions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) {
			return {
				...interaction,
				currentState: 'FINISHING',
				finishedAt: new Date(),
				messages: [...interaction.messages, stateMessage]
			};
		}

		return interaction;
	});
	let pendingInteractions = state.pendingInteractions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) {
			return {
				...interaction,
				currentState: 'FINISHING',
				finishedAt: new Date(),
				messages: [...interaction.messages, stateMessage]
			};
		}

		return interaction;
	});

	if (removeThisInteraction) {
		interactions = interactions.filter(interaction => (
			interaction.interactionHash !== interactionHash
		));
		pendingInteractions = pendingInteractions.filter(interaction => (
			interaction.interactionHash !== interactionHash
		));
	}

	return updateObject(state, { interactions, pendingInteractions });
};

const onFinished = (state, action) => {
	const { interactionHash, expired, removeThisInteraction } = action.payload;
	const stateMessage = {
		expired,
		state: 'ENDED',
		time: new Date(),
		type: 'state'
	};
	// FIXME: Duplicated code
	let interactions = state.interactions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) {
			return {
				...interaction,
				currentState: 'ENDED',
				messages: [...interaction.messages, stateMessage]
			};
		}

		return interaction;
	});

	let pendingInteractions = state.pendingInteractions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) {
			return {
				...interaction,
				currentState: 'ENDED',
				messages: [...interaction.messages, stateMessage]
			};
		}

		return interaction;
	});

	if (removeThisInteraction) {
		interactions = interactions.filter(interaction => (
			interaction.interactionHash !== interactionHash
		));
		pendingInteractions = pendingInteractions.filter(interaction => (
			interaction.interactionHash !== interactionHash
		));
	}

	return updateObject(state, { interactions, pendingInteractions });
};

const onRemoveInteraction = (state, action) => {
	const { interactionHash, registeredPhone, phoneData } = action.payload;
	// FIXME: Duplicated code
	const interactions = state.interactions.filter(i => i.interactionHash !== interactionHash);
	const pendingInteractions = state.pendingInteractions.filter(
		i => i.interactionHash !== interactionHash
	);

	const hasActiveChat = verifyIfHasActiveChat(interactions);

	if (!hasActiveChat && registeredPhone) {
		const { name, station, token } = phoneData;
		if (name === 'totalVoice' && !window.ttvSdk.connected && state.onlyChatOrPhone) window.ttvSdk.connect(token, station);
	}

	return updateObject(state, { interactions, pendingInteractions, hasActiveChat });
};

const fetchQueueInteractions = (state, action) => {
	const { interactions, fetchQueueInteractionsStatus } = state;
	const { count = 1 } = fetchQueueInteractionsStatus;
	const fetchedInteractions = action.payload.map(interaction => mapNewInteractionItem(interaction));

	const updatedInteractions = [...interactions];
	fetchedInteractions.forEach((fetchedInteraction) => {
		const { interactionHash } = fetchedInteraction;
		const existingInteractionIndex = interactions.findIndex(
			interaction => interaction.interactionHash === interactionHash
		);
		if (existingInteractionIndex === -1) {
			updatedInteractions.push(fetchedInteraction);
		} else {
			interactions[existingInteractionIndex] = fetchedInteraction;
		}
	});

	const { currentInteractionHash } = state;
	let currentInteractionIsActive = false;
	state.interactions.concat(state.pendingInteractions).forEach((interaction) => {
		if (interaction.interactionHash === currentInteractionHash) {
			currentInteractionIsActive = ACTIVE_INTERACTION_STATES.findIndex(
				status => status === interaction.currentState
			) > -1;
		}
	});

	return updateObject(state, {
		fetchQueueInteractionsStatus: {
			...setRequestStatus('success'),
			count: count + 1,
			ended: fetchedInteractions.length === 0
		},
		interactions: updatedInteractions,
		currentInteractionHash: currentInteractionIsActive ? currentInteractionHash : ''
	});
};

const fetchInboxInteractions = (state, action) => {
	const { pendingInteractions, fetchInboxInteractionsStatus } = state;
	const { count = 1 } = fetchInboxInteractionsStatus;
	const fetchedInteractions = action.payload.map(interaction => mapNewInteractionItem(interaction));

	const updatedInteractions = [...pendingInteractions];
	fetchedInteractions.forEach((fetchedInteraction) => {
		const { interactionHash } = fetchedInteraction;
		const existingInteractionIndex = pendingInteractions.findIndex(
			interaction => interaction.interactionHash === interactionHash
		);
		if (existingInteractionIndex === -1) {
			updatedInteractions.push(fetchedInteraction);
		} else {
			pendingInteractions[existingInteractionIndex] = fetchedInteraction;
		}
	});

	const { currentInteractionHash } = state;
	let currentInteractionIsActive = false;
	state.interactions.concat(state.pendingInteractions).forEach((interaction) => {
		if (interaction.interactionHash === currentInteractionHash) {
			currentInteractionIsActive = ACTIVE_INTERACTION_STATES.findIndex(
				status => status === interaction.currentState
			) > -1;
		}
	});

	return updateObject(state, {
		fetchInboxInteractionsStatus: {
			...setRequestStatus('success'),
			count: count + 1,
			ended: fetchedInteractions.length === 0
		},
		pendingInteractions: updatedInteractions,
		currentInteractionHash: currentInteractionIsActive ? currentInteractionHash : ''
	});
};

const fetchMissedInteractions = (state, action) => {
	const { missedInteractions, fetchMissedInteractionsStatus } = state;
	const { count = 1 } = fetchMissedInteractionsStatus;
	const fetchedInteractions = action.payload.map(interaction => mapNewInteractionItem(interaction));

	const updatedInteractions = [...missedInteractions];
	fetchedInteractions.forEach((fetchedInteraction) => {
		const { interactionHash } = fetchedInteraction;
		const existingInteractionIndex = missedInteractions.findIndex(
			interaction => interaction.interactionHash === interactionHash
		);
		if (existingInteractionIndex === -1) {
			updatedInteractions.push(fetchedInteraction);
		} else {
			missedInteractions[existingInteractionIndex] = fetchedInteraction;
		}
	});

	const { currentInteractionHash } = state;
	let currentInteractionIsActive = false;
	state.interactions.concat(state.missedInteractions).forEach((interaction) => {
		if (interaction.interactionHash === currentInteractionHash) {
			currentInteractionIsActive = ACTIVE_INTERACTION_STATES.findIndex(
				status => status === interaction.currentState
			) > -1;
		}
	});

	return updateObject(state, {
		fetchMissedInteractionsStatus: {
			...setRequestStatus('success'),
			count: count + 1,
			ended: fetchedInteractions.length === 0
		},
		missedInteractions: updatedInteractions,
		currentInteractionHash: currentInteractionIsActive ? currentInteractionHash : ''
	});
};

const updateCustomer = (state, action) => {
	const { fields, customerKey, note } = action.payload;

	// FIXME: Duplicated code
	const interactions = state.interactions.map((interaction) => {
		if (interaction.customerInfo.customerKey === customerKey) {
			return {
				...interaction,
				customerInfo: {
					...interaction.customerInfo,
					fields,
					note
				}
			};
		}
		return interaction;
	});

	const pendingInteractions = state.pendingInteractions.map((interaction) => {
		if (interaction.customerInfo.customerKey === customerKey) {
			return {
				...interaction,
				customerInfo: {
					...interaction.customerInfo,
					fields,
					note
				}
			};
		}
		return interaction;
	});

	return updateObject(state, {
		interactions,
		pendingInteractions,
		updateCustomerStatus: manageInterectionStatus(state, 'success', 'updateCustomerStatus')
	});
};

const mergeCustomer = (state, action) => {
	const { customer, interactionHash, oldCustomerKey } = action.payload;
	const { fetchHistoryStatus } = state;

	// FIXME: Duplicated code
	const interactions = state.interactions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) {
			return {
				...interaction,
				customerInfo: {
					...customer
				},
				searchedCustomers: [...interaction.searchedCustomers.filter(customerItem => (
					customerItem.customerKey !== oldCustomerKey
				))]
			};
		}
		return interaction;
	});

	const pendingInteractions = state.pendingInteractions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) {
			return {
				...interaction,
				customerInfo: {
					...customer
				},
				searchedCustomers: [...interaction.searchedCustomers.filter(customerItem => (
					customerItem.customerKey !== oldCustomerKey
				))]
			};
		}
		return interaction;
	});

	const updatedHistoryStatus = fetchHistoryStatus.map((status) => {
		if (status.hash === interactionHash) {
			return {
				...status,
				count: null,
				ended: false
			};
		}
		return status;
	});

	return updateObject(state, {
		interactions,
		pendingInteractions,
		mergeCustomerStatus: setRequestStatus('success'),
		fetchHistoryStatus: updatedHistoryStatus
	});
};

const fetchCustomer = (state, action) => {
	const { customers, interactionHash, customerKey } = action.payload;

	const filteredCustomers = customers.filter(customer => customer.customerKey !== customerKey);
	// FIXME: Duplicated code
	const interactions = state.interactions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) {
			return {
				...interaction,
				searchedCustomers: filteredCustomers
			};
		}
		return interaction;
	});
	const pendingInteractions = state.pendingInteractions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) {
			return {
				...interaction,
				searchedCustomers: filteredCustomers
			};
		}
		return interaction;
	});

	return updateObject(state, {
		interactions,
		pendingInteractions,
		fetchCustomerStatus: manageInterectionStatus(state, 'success', 'fetchCustomerStatus')
	});
};

const fetchHistory = (state, action) => {
	const { historyInteractions, customerKey, isSearching } = action.payload;
	const { fetchHistoryStatus } = state;

	const currentInteraction = state.interactions
		.concat(state.pendingInteractions)
		.concat(state.historyInteractions).find(
			interaction => (interaction.customerInfo.customerKey === customerKey)
		);

	const currentInteractionStatus = currentInteraction ? fetchHistoryStatus.find(status => (
		status.hash === currentInteraction.interactionHash
	)) : {};

	const { count = 1 } = currentInteractionStatus;

	const { history = [] } = currentInteraction.customerInfo;

	const updatedHistory = history;

	historyInteractions.forEach((historyInteraction) => {
		const { id } = historyInteraction;
		const existingHistoryIndex = updatedHistory.findIndex(
			historyItem => historyItem.id === id
		);
		if (existingHistoryIndex === -1) {
			updatedHistory.push(historyInteraction);
		} else {
			updatedHistory[existingHistoryIndex] = historyInteraction;
		}
	});

	// FIXME: Duplicated code
	const interactions = state.interactions.map((interaction) => {
		if (interaction.customerInfo.customerKey === customerKey) {
			return {
				...interaction,
				customerInfo: {
					...interaction.customerInfo,
					history: isSearching ? (
						historyInteractions.map(interactionItem => mapNewInteractionItem(interactionItem))
					) : updatedHistory.map(interactionItem => mapNewInteractionItem(interactionItem))
				}
			};
		}
		return interaction;
	});
	const pendingInteractions = state.pendingInteractions.map((interaction) => {
		if (interaction.customerInfo.customerKey === customerKey) {
			return {
				...interaction,
				customerInfo: {
					...interaction.customerInfo,
					history: isSearching ? (
						historyInteractions.map(interactionItem => mapNewInteractionItem(interactionItem))
					) : updatedHistory.map(interactionItem => mapNewInteractionItem(interactionItem))
				}
			};
		}
		return interaction;
	});
	const historyInteractionsState = state.historyInteractions.map((interaction) => {
		if (interaction.customerInfo.customerKey === customerKey) {
			return {
				...interaction,
				customerInfo: {
					...interaction.customerInfo,
					history: isSearching ? (
						historyInteractions.map(interactionItem => mapNewInteractionItem(interactionItem))
					) : updatedHistory.map(interactionItem => mapNewInteractionItem(interactionItem))
				}
			};
		}
		return interaction;
	});

	const updatedfetchHistoryStatus = fetchHistoryStatus.map((status) => {
		if (status.hash === currentInteraction.interactionHash) {
			return {
				...status,
				success: true,
				count: count + 1,
				ended: historyInteractions.length === 0,
				loading: false
			};
		}

		return status;
	});

	return updateObject(state, {
		interactions,
		pendingInteractions,
		historyInteractions: historyInteractionsState,
		fetchHistoryStatus: updatedfetchHistoryStatus
	});
};

const fetchHistoryBegin = (state, action) => {
	const { payload } = action;
	const { fetchHistoryStatus } = state;

	const currentInteraction = state.interactions
		.concat(state.pendingInteractions)
		.concat(state.historyInteractions).find(
			interaction => (interaction.customerInfo.customerKey === payload)
		);

	let existsStatus = false;
	const updatedfetchHistoryStatus = fetchHistoryStatus.map((status) => {
		if (status.hash === currentInteraction.interactionHash) {
			existsStatus = true;
			return {
				...status,
				loading: true
			};
		}

		return status;
	});

	if (!existsStatus) {
		updatedfetchHistoryStatus.push({
			hash: currentInteraction.interactionHash,
			loading: true
		});
	}

	return updateObject(state, {
		fetchHistoryStatus: updatedfetchHistoryStatus
	});
};

const updateInteractionCustomer = (state, action) => {
	const {
		fields, interactionHash, customerKey, historyCount
	} = action.payload;
	const { fetchHistoryStatus = [] } = state;

	// FIXME: Duplicated code
	const interactions = state.interactions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) {
			return {
				...interaction,
				customerInfo: {
					...interaction.customerInfo,
					fields,
					customerKey,
					historyCount,
					history: []
				},
				searchedCustomers: [...interaction.searchedCustomers.filter(customer => (
					customer.customerKey !== customerKey
				))]
			};
		}
		return interaction;
	});
	const pendingInteractions = state.pendingInteractions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) {
			return {
				...interaction,
				customerInfo: {
					...interaction.customerInfo,
					fields,
					customerKey,
					historyCount,
					history: []
				},
				searchedCustomers: [...interaction.searchedCustomers.filter(customer => (
					customer.customerKey !== customerKey
				))]
			};
		}
		return interaction;
	});

	const updatedHistoryStatus = fetchHistoryStatus.map((status) => {
		if (status.hash === interactionHash) {
			return {
				...status,
				count: null,
				ended: false
			};
		}
		return status;
	});

	return updateObject(state, {
		interactions,
		pendingInteractions,
		fetchHistoryStatus: updatedHistoryStatus,
		updateInteractionCustomerStatus: manageInterectionStatus(state, 'success', 'updateInteractionCustomerStatus')
	});
};

const fetchInteractionHistory = (state, action) => {
	const { historyInteraction, interactionHash } = action.payload;
	const { tags, note } = historyInteraction;

	const parsedMessages = historyInteraction.parsed_messages
		? historyInteraction.parsed_messages.map(message => (mapNewMessageItem(message)))
		: [];

	// FIXME: Duplicate code
	const interactions = state.interactions.map(interaction => ({
		...interaction,
		customerInfo: {
			...interaction.customerInfo,
			history: interaction.customerInfo.history
				? interaction.customerInfo.history.map((customerInteraction) => {
					if (customerInteraction.interactionHash === interactionHash) {
						return {
							...customerInteraction,
							messages: parsedMessages,
							tags,
							note
						};
					}

					return customerInteraction;
				}) : []
		}
	}));

	const pendingInteractions = state.pendingInteractions.map(interaction => ({
		...interaction,
		customerInfo: {
			...interaction.customerInfo,
			history: interaction.customerInfo.history
				? interaction.customerInfo.history.map((customerInteraction) => {
					if (customerInteraction.interactionHash === interactionHash) {
						return {
							...customerInteraction,
							messages: parsedMessages,
							tags,
							note
						};
					}

					return customerInteraction;
				}) : []
		}
	}));

	const historyInteractions = state.historyInteractions.map(interaction => ({
		...interaction,
		customerInfo: {
			...interaction.customerInfo,
			history: interaction.customerInfo.history
				? interaction.customerInfo.history.map((customerInteraction) => {
					if (customerInteraction.interactionHash === interactionHash) {
						return {
							...customerInteraction,
							messages: parsedMessages,
							tags,
							note
						};
					}

					return customerInteraction;
				}) : []
		}
	}));

	return updateObject(state, {
		interactions,
		pendingInteractions,
		historyInteractions,
		fetchInteractionHistoryStatus: manageInterectionStatus(state, 'success', 'fetchInteractionHistoryStatus')
	});
};

const updateNote = (state, action) => {
	const { note, interactionHash } = action.payload;
	const interactions = state.interactions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) {
			return {
				...interaction,
				note
			};
		}
		return interaction;
	});
	const pendingInteractions = state.pendingInteractions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) {
			return {
				...interaction,
				note
			};
		}
		return interaction;
	});

	return updateObject(state, {
		updateNoteStatus: manageInterectionStatus(state, 'success', 'updateNoteStatus'),
		interactions,
		pendingInteractions
	});
};

const updateTags = (state, action) => {
	const { tags, interactionHash } = action.payload;
	const interactions = state.interactions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) {
			return {
				...interaction,
				interactionTags: tags
			};
		}
		return interaction;
	});
	const pendingInteractions = state.pendingInteractions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) {
			return {
				...interaction,
				interactionTags: tags
			};
		}
		return interaction;
	});

	return updateObject(state, {
		interactions,
		pendingInteractions,
		updateTagsStatus: manageInterectionStatus(state, 'success', 'updateTagsStatus')
	});
};

const fetchAgentHistory = (state, action) => {
	const { historyInteractions = [] } = state;
	const { count, interactions } = action.payload;
	const fetchedHistories = interactions.map(interaction => mapNewInteractionItem(interaction));
	const updatedHistory = count === 1 ? [] : [...historyInteractions];

	fetchedHistories.forEach((fetchedInteraction) => {
		const { interactionHash } = fetchedInteraction;
		const existingInteractionIndex = updatedHistory.findIndex(
			interaction => interaction.interactionHash === interactionHash
		);

		if (existingInteractionIndex === -1) {
			updatedHistory.push(fetchedInteraction);
		} else {
			updatedHistory[existingInteractionIndex] = fetchedInteraction;
		}
	});

	return updateObject(state, {
		fetchAgentHistoryStatus: {
			...setRequestStatus('success'),
			count: count + 1,
			ended: fetchedHistories.length === 0
		},
		historyInteractions: updatedHistory
	});
};

const sendAttachmentEmail = (state, action) => {
	const { attachment, interactionHash } = action.payload;
	const parsedAttachment = {
		...attachment,
		name: attachment.name
	};

	const interactions = state.interactions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) {
			const updatedAttachments = interaction.attachments || [];
			updatedAttachments.push(parsedAttachment);
			return {
				...interaction,
				attachments: updatedAttachments
			};
		}
		return interaction;
	});
	const pendingInteractions = state.pendingInteractions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) {
			const updatedAttachments = interaction.attachments || [];
			updatedAttachments.push(parsedAttachment);
			return {
				...interaction,
				attachments: updatedAttachments
			};
		}
		return interaction;
	});

	return updateObject(state, {
		interactions,
		pendingInteractions,
		sendAttachmentEmailStatus: manageInterectionStatus(state, 'success', 'sendAttachmentEmailStatus')
	});
};

const clearAttachmentEmail = (state, action) => {
	const { name = '', interactionHash } = action.payload;

	const clearAll = name === '';

	const interactions = state.interactions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) {
			const updatedAttachments = interaction.attachments ? interaction.attachments.filter(
				attachment => attachment.name !== name
			) : [];
			return {
				...interaction,
				attachments: clearAll ? [] : updatedAttachments
			};
		}
		return interaction;
	});
	const pendingInteractions = state.pendingInteractions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) {
			const updatedAttachments = interaction.attachments ? interaction.attachments.filter(
				attachment => attachment.name !== name
			) : [];
			return {
				...interaction,
				attachments: clearAll ? [] : updatedAttachments
			};
		}
		return interaction;
	});

	return updateObject(state, {
		interactions,
		pendingInteractions,
		sendAttachmentEmailStatus: manageInterectionStatus(state, 'success', 'sendAttachmentEmailStatus')
	});
};

const createdInteractionTask = (state, action) => {
	const { task, interactionHash } = action.payload;

	const parsedTasks = [];
	parsedTasks.push(task);

	const interactions = state.interactions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) {
			return {
				...interaction,
				tasks: parsedTasks
			};
		}

		return interaction;
	});

	const pendingInteractions = state.pendingInteractions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) {
			return {
				...interaction,
				tasks: parsedTasks
			};
		}

		return interaction;
	});

	return updateObject(state, {
		interactions,
		pendingInteractions,
		createdOrUpdatedTask: true
	});
};

const updateInteractionStatus = (state, action) => {
	const { status, interactionHash } = action.payload;

	const interactions = state.interactions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) {
			return {
				...interaction,
				status
			};
		}
		return interaction;
	});

	const pendingInteractions = state.pendingInteractions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) {
			return {
				...interaction,
				status
			};
		}
		return interaction;
	});

	return updateObject(state, {
		interactions,
		pendingInteractions
	});
};

const finishInteractionTask = (state, action) => {
	const { payload } = action;
	const interactions = state.interactions.map((interaction) => {
		const { tasks = [] } = interaction;
		const updatedTasks = tasks.map((task) => {
			if (task.id === payload) return { ...task, status: 'FINISHED' };
			return task;
		});

		return { ...interaction, tasks: updatedTasks };
	});

	const pendingInteractions = state.pendingInteractions.map((interaction) => {
		const { tasks = [] } = interaction;
		const updatedTasks = tasks.map((task) => {
			if (task.id === payload) return { ...task, status: 'FINISHED' };
			return task;
		});

		return { ...interaction, tasks: updatedTasks };
	});

	return updateObject(state, { interactions, pendingInteractions });
};

const fetchTransferData = (state, action) => {
	const stateTransferData = state.transferData;
	const { page, transferData } = action.payload;
	return updateObject(state, {
		fetchTransferDataStatus: {
			success: true,
			loading: false,
			page: action.payload.page + 1,
			ended: action.payload.transferData.agents.length === 0
		},
		transferData: page === 1 ? transferData : {
			...stateTransferData,
			agents: [
				...stateTransferData.agents,
				...action.payload.transferData.agents
			],
			departments: [
				...stateTransferData.departments,
				...action.payload.transferData.departments
			]
		}
	});
};

const fetchInteractionTags = (state, { payload }) => {
	const { interactionHash, tags } = payload;

	const interactions = state.interactions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) return { ...interaction, tags };
		return interaction;
	});

	const pendingInteractions = state.pendingInteractions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) return { ...interaction, tags };
		return interaction;
	});

	return updateObject(state, { interactions, pendingInteractions, tagsStatus: setRequestStatus('success') });
};

const fetchInteractionSubtags = (state, { payload }) => {
	const { interactionHash, tags, level } = payload;

	const subtags = {};
	subtags[`subtags${level}`] = tags;

	const interactions = state.interactions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) return { ...interaction, ...subtags };
		return interaction;
	});

	const pendingInteractions = state.pendingInteractions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) return { ...interaction, ...subtags };
		return interaction;
	});

	return updateObject(state, { interactions, pendingInteractions, subtagsStatus: setRequestStatus('success') });
};

const fetchInteractionAnswers = (state, { payload }) => {
	const { interactionHash, shortcuts, page } = payload;

	const interactions = state.interactions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) return updateObject(interaction, { answers: page === 1 ? shortcuts : [...interaction.answers, ...shortcuts] });
		return interaction;
	});

	const pendingInteractions = state.pendingInteractions.map((interaction) => {
		if (interaction.interactionHash === interactionHash) return updateObject(interaction, { answers: page === 1 ? shortcuts : [...interaction.answers, ...shortcuts] });
		return interaction;
	});

	return updateObject(
		state,
		{
			interactions,
			pendingInteractions,
			answersStatus: {
				...setRequestStatus('success'),
				ended: payload.shortcuts.length === 0,
				page: payload.page + 1
			}
		}
	);
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
	case actionTypes.FETCH_QUEUE_INTERACTIONS_BEGIN:
		return updateObject(state, {
			interactions: action.payload === 1
				? state.interactions.filter(i => ACTIVE_INTERACTION_STATES.indexOf(i.currentState) > -1)
				: state.interactions,
			fetchQueueInteractionsStatus: {
				...state.fetchQueueInteractionsStatus, ...setRequestStatus()
			},
			fetchInteractionInfoStatus: []
		});
	case actionTypes.FETCH_QUEUE_INTERACTIONS_SUCCESS:
		return fetchQueueInteractions(state, action);
	case actionTypes.FETCH_QUEUE_INTERACTIONS_FAILED:
		return updateObject(state, {
			fetchQueueInteractionsStatus: { ...state.fetchQueueInteractionsStatus, ...setRequestStatus('failed') }
		});

	case actionTypes.FETCH_INBOX_INTERACTIONS_BEGIN:
		return updateObject(state, {
			pendingInteractions: action.payload === 1 ? [] : state.pendingInteractions,
			fetchInboxInteractionsStatus: {
				...state.fetchInboxInteractionsStatus, ...setRequestStatus()
			},
			fetchInteractionInfoStatus: []
		});
	case actionTypes.FETCH_INBOX_INTERACTIONS_SUCCESS:
		return fetchInboxInteractions(state, action);
	case actionTypes.FETCH_INBOX_INTERACTIONS_FAILED:
		return updateObject(state, {
			fetchInboxInteractionsStatus: { ...state.fetchInboxInteractionsStatus, ...setRequestStatus('failed') }
		});

	case actionTypes.FETCH_MISSED_INTERACTIONS_BEGIN:
		return updateObject(state, {
			missedInteractions: action.payload === 1 ? [] : state.missedInteractions,
			fetchMissedInteractionsStatus: {
				...state.fetchMissedInteractionsStatus, ...setRequestStatus()
			},
			fetchInteractionInfoStatus: []
		});
	case actionTypes.FETCH_MISSED_INTERACTIONS_SUCCESS:
		return fetchMissedInteractions(state, action);
	case actionTypes.FETCH_MISSED_INTERACTIONS_FAILED:
		return updateObject(state, {
			fetchMissedInteractionsStatus: { ...state.fetchMissedInteractionsStatus, ...setRequestStatus('failed') }
		});

	case actionTypes.FETCH_INTERACTIONS_COUNT_BEGIN:
		return updateObject(state, { fetchInteractionsCountStatus: setRequestStatus() });
	case actionTypes.FETCH_INTERACTIONS_COUNT_SUCCESS:
		return fetchInteractionsCount(state, action);
	case actionTypes.FETCH_INTERACTIONS_COUNT_FAILED:
		return updateObject(state, { fetchInteractionsCountStatus: setRequestStatus('failed') });

	case actionTypes.RESET_INBOX_INTERACTION_PAGE_COUNT:
		return updateObject(state, {
			fetchInboxInteractionsStatus: { ...state.fetchInboxInteractionsStatus, count: 1 }
		});
	case actionTypes.RESET_QUEUE_INTERACTION_PAGE_COUNT:
		return updateObject(state, {
			fetchQueueInteractionsStatus: { ...state.fetchQueueInteractionsStatus, count: 1 }
		});
	case actionTypes.RESET_MISSED_INTERACTION_PAGE_COUNT:
		return updateObject(state, {
			fetchMissedInteractionsStatus: { ...state.fetchMissedInteractionsStatus, count: 1 }
		});

	case actionTypes.UPDATE_NOTE_BEGIN:
		return updateObject(state, {
			updateNoteStatus: manageInterectionStatus(state, null, 'updateNoteStatus')
		});
	case actionTypes.UPDATE_NOTE_SUCCESS:
		return updateNote(state, action);
	case actionTypes.UPDATE_NOTE_FAILED:
		return updateObject(state, {
			updateNoteStatus: manageInterectionStatus(state, 'failed', 'updateNoteStatus')
		});
	case actionTypes.UPDATE_NOTE_CHANGED:
		return updateObject(state, {
			updateNoteStatus: manageInterectionStatus(state, 'clear', 'updateNoteStatus')
		});

	case actionTypes.UPDATE_TAGS_BEGIN:
		return updateObject(state, {
			updateTagsStatus: manageInterectionStatus(state, null, 'updateTagsStatus')
		});
	case actionTypes.UPDATE_TAGS_SUCCESS:
		return updateTags(state, action);
	case actionTypes.UPDATE_TAGS_FAILED:
		return updateObject(state, {
			updateTagsStatus: manageInterectionStatus(state, 'failed', 'updateTagsStatus')
		});
	case actionTypes.UPDATE_TAGS_CHANGED:
		return updateObject(state, {
			updateTagsStatus: manageInterectionStatus(state, 'changed', 'updateTagsStatus')
		});

	case actionTypes.FETCH_TRANSFER_DATA_BEGIN:
		return updateObject(state, {
			fetchTransferDataStatus: setRequestStatus()
		});
	case actionTypes.FETCH_TRANSFER_DATA_SUCCESS:
		return fetchTransferData(state, action);
	case actionTypes.FETCH_TRANSFER_DATA_FAILED:
		return updateObject(state, {
			fetchTransferDataStatus: setRequestStatus('failed')
		});

	case actionTypes.UPDATE_INTERACTION_CUSTOMER_BEGIN:
		return updateObject(state, {
			updateInteractionCustomerStatus: manageInterectionStatus(state, null, 'updateInteractionCustomerStatus')
		});
	case actionTypes.UPDATE_INTERACTION_CUSTOMER_SUCCESS:
		return updateInteractionCustomer(state, action);
	case actionTypes.UPDATE_INTERACTION_CUSTOMER_FAILED:
		return updateObject(state, {
			updateInteractionCustomerStatus: manageInterectionStatus(state, 'failed', 'updateInteractionCustomerStatus')
		});

	case actionTypes.FETCH_INTERACTION_HISTORY_BEGIN:
		return updateObject(state, {
			fetchInteractionHistoryStatus: manageInterectionStatus(state, null, 'fetchInteractionHistoryStatus')
		});
	case actionTypes.FETCH_INTERACTION_HISTORY_SUCCESS:
		return fetchInteractionHistory(state, action);
	case actionTypes.FETCH_INTERACTION_HISTORY_FAILED:
		return updateObject(state, {
			fetchInteractionHistoryStatus: manageInterectionStatus(state, 'failed', 'fetchInteractionHistoryStatus')
		});

	case actionTypes.FETCH_CUSTOMER_BEGIN:
		return updateObject(state, {
			fetchCustomerStatus: manageInterectionStatus(state, null, 'fetchCustomerStatus')
		});
	case actionTypes.FETCH_CUSTOMER_SUCCESS:
		return fetchCustomer(state, action);
	case actionTypes.FETCH_CUSTOMER_FAILED:
		return updateObject(state, {
			fetchCustomerStatus: manageInterectionStatus(state, 'failed', 'fetchCustomerStatus')
		});

	case actionTypes.UPDATE_CUSTOMER_BEGIN:
		return updateObject(state, {
			updateCustomerStatus: manageInterectionStatus(state, null, 'updateCustomerStatus')
		});
	case actionTypes.UPDATE_CUSTOMER_SUCCESS:
		return updateCustomer(state, action);
	case actionTypes.UPDATE_CUSTOMER_FAILED:
		return updateObject(state, {
			updateCustomerStatus: manageInterectionStatus(state, 'failed', 'updateCustomerStatus', action.payload)
		});
	case actionTypes.UPDATE_CUSTOMER_CLEAR:
		return updateObject(state, {
			updateCustomerStatus: manageInterectionStatus(state, 'clear', 'updateCustomerStatus')
		});

	case actionTypes.FETCH_HISTORY_BEGIN:
		return fetchHistoryBegin(state, action);
	case actionTypes.FETCH_HISTORY_SUCCESS:
		return fetchHistory(state, action);
	case actionTypes.FETCH_HISTORY_FAILED:
		return updateObject(state, {
			fetchHistoryStatus: manageInterectionStatus(state, 'failed', 'fetchHistoryStatus')
		});

	case actionTypes.SEARCH_INBOX_INTERACTIONS_BEGIN:
		return updateObject(state, {
			searchInteractionsStatus: setRequestStatus(),
			fetchInteractionInfoStatus: [],
			currentInteractionHash: ''
		});
	case actionTypes.SEARCH_INBOX_INTERACTIONS_SUCCESS:
		return updateObject(state, {
			searchInteractionsStatus: setRequestStatus('success'),
			filteredPendingInteractions: action.payload.map(interaction => mapNewInteractionItem(interaction))
		});
	case actionTypes.SEARCH_INBOX_INTERACTIONS_FAILED:
		return updateObject(state, {
			searchInteractionsStatus: setRequestStatus('failed')
		});

	case actionTypes.SEARCH_QUEUE_INTERACTIONS_BEGIN:
		return updateObject(state, {
			searchInteractionsStatus: setRequestStatus(),
			fetchInteractionInfoStatus: [],
			currentInteractionHash: ''
		});
	case actionTypes.SEARCH_QUEUE_INTERACTIONS_SUCCESS:
		return updateObject(state, {
			searchInteractionsStatus: setRequestStatus('success'),
			filteredInteractions: action.payload.map(interaction => mapNewInteractionItem(interaction))
		});
	case actionTypes.SEARCH_QUEUE_INTERACTIONS_FAILED:
		return updateObject(state, {
			searchInteractionsStatus: setRequestStatus('failed')
		});

	case actionTypes.SEARCH_MISSED_INTERACTIONS_BEGIN:
		return updateObject(state, {
			searchInteractionsStatus: setRequestStatus(),
			fetchInteractionInfoStatus: [],
			currentInteractionHash: ''
		});
	case actionTypes.SEARCH_MISSED_INTERACTIONS_SUCCESS:
		return updateObject(state, {
			searchInteractionsStatus: setRequestStatus('success'),
			filteredMissedInteractions: action.payload.map(interaction => mapNewInteractionItem(interaction))
		});
	case actionTypes.SEARCH_MISSED_INTERACTIONS_FAILED:
		return updateObject(state, {
			searchInteractionsStatus: setRequestStatus('failed')
		});

	case actionTypes.MERGE_CUSTOMER_BEGIN:
		return updateObject(state, {
			mergeCustomerStatus: setRequestStatus()
		});
	case actionTypes.MERGE_CUSTOMER_CLEAR:
		return updateObject(state, {
			mergeCustomerStatus: {}
		});
	case actionTypes.MERGE_CUSTOMER_SUCCESS:
		return mergeCustomer(state, action);
	case actionTypes.MERGE_CUSTOMER_FAILED:
		return updateObject(state, {
			mergeCustomerStatus: setRequestStatus('failed')
		});
	case actionTypes.FETCH_AGENT_HISTORY_BEGIN:
		return updateObject(state, {
			fetchAgentHistoryStatus: {
				...state.fetchAgentHistoryStatus,
				loading: true
			}
		});
	case actionTypes.FETCH_AGENT_HISTORY_SUCCESS:
		return fetchAgentHistory(state, action);
	case actionTypes.FETCH_AGENT_HISTORY_FAILED:
		return updateObject(state, {
			fetchAgentHistoryStatus: {
				...state.fetchAgentHistoryStatus,
				loading: false
			}
		});
	case actionTypes.SEARCH_AGENT_HISTORY_BEGIN:
		return updateObject(state, {
			searchingHistory: true
		});
	case actionTypes.SEARCH_AGENT_HISTORY_SUCCESS:
		return updateObject(state, {
			historyInteractions: action.payload.map(interaction => mapNewInteractionItem(interaction)),
			searchingHistory: false,
			fetchAgentHistoryStatus: {
				count: 1,
				ended: false
			}
		});
	case actionTypes.SEARCH_AGENT_HISTORY_FAILED:
		return updateObject(state, {
			searchingHistory: false
		});
	case actionTypes.SEND_EMAIL_ATTACHMENT_BEGIN:
		return updateObject(state, {
			sendAttachmentEmailStatus: manageInterectionStatus(state, null, 'sendAttachmentEmailStatus')
		});
	case actionTypes.CREATED_INTERACTION_TASK:
		return createdInteractionTask(state, action);
	case actionTypes.CREATED_INTERACTION_TASK_CLEAR:
		return updateObject(state, {
			createdOrUpdatedTask: false
		});
	case actionTypes.SEND_EMAIL_ATTACHMENT_SUCCESS:
		return sendAttachmentEmail(state, action);
	case actionTypes.SEND_EMAIL_ATTACHMENT_CLEAR:
		return clearAttachmentEmail(state, action);
	case actionTypes.SEND_EMAIL_ATTACHMENT_FAILED:
		return updateObject(state, {
			sendAttachmentEmailStatus: manageInterectionStatus(state, 'failed', 'sendAttachmentEmailStatus')
		});
	case actionTypes.UPDATE_INTERACTION_STATUS:
		return updateInteractionStatus(state, action);
	case actionTypes.SET_PENDING_MESSAGES:
		return setPendingMessages(state, action);
	case actionTypes.CLEAR_PENDING_MESSAGES:
		return clearPendingMessages(state, action);
	case actionTypes.DEFINE_SELECTED_INTERACTION:
		return defineSelectedInteraction(state, action);
	case actionTypes.SET_CURRENT_INTERACTION:
		return setCurrentInteraction(state, action);
	case actionTypes.ON_ACCEPT_INTERACTION_BEGIN:
		return acceptInteractionBegin(state, action);
	case actionTypes.LOAD_INTERACTION_INFO_BEGIN:
		return onLoadInteractionInfoBegin(state, action);
	case actionTypes.LOAD_INTERACTION_INFO_SUCCESS:
		return onLoadInteractionInfoSuccess(state, action);
	case actionTypes.LOAD_INTERACTION_INFO_FAILED:
		return onLoadInteractionInfoFailed(state, action);
	case actionTypes.ON_NEW_INTERACTION_SUCCESS:
		return onNewInteraction(state, action);
	case actionTypes.ON_ACCEPT_INTERACTION_SUCCESS:
		return onAcceptedInteraction(state, action);
	case actionTypes.ON_REPLY_INTERACTION_SUCCESS:
		return onRepliedInteraction(state, action);
	case actionTypes.ON_UPDATE_INTERACTION_SUCCESS:
		return onUpdateInteraction(state, action);
	case actionTypes.ON_NEW_MESSAGE_SUCCESS:
		return onNewMessage(state, action);
	case actionTypes.ON_SEND_MESSAGE_SUCCESS:
		return onSendMessage(state, action);
	case actionTypes.ON_RECEIVED_MESSAGE_SUCCESS:
		return onReceivedMessage(state, action);
	case actionTypes.ON_NEW_WHATSAPP_VOICE_CALL_SUCCESS:
		return onNewWhatsappVoiceCall(state, action);
	case actionTypes.ON_NEW_WHATSAPP_MEDIA_STREAM:
		return onNewWhatsappMediaStream(state, action);
	case actionTypes.ON_TERMINATE_WHATSAPP_VOICE_CALL_SUCCESS:
		return onTerminateWhatsappVoiceCallSuccess(state, action);
	case actionTypes.ON_WHATSAPP_CALL_DURATION_SUCCESS:
		return onWhatsappCallDuration(state, action);
	case actionTypes.UPDATE_CALL_DURATION:
		return updateCallDuration(state, action);
	case actionTypes.ON_DELIVERED_MESSAGE_SUCCESS:
		return onDeliveredMessage(state, action);
	case actionTypes.ON_TYPING_SUCCESS:
		return onTyping(state, action);
	case actionTypes.ON_CLEARED_SUCCESS:
		return onCleared(state, action);
	case actionTypes.ON_TRANSFERRED_SUCCESS:
		return onTransferred(state, action);
	case actionTypes.ON_FINISHING:
		return onFinishing(state, action);
	case actionTypes.ON_FINISHED_SUCCESS:
		return onFinished(state, action);
	case actionTypes.REMOVE_INTERACTION:
		return onRemoveInteraction(state, action);
	case actionTypes.SET_ONLY_CHAT_OR_PHONE:
		return updateObject(state, {
			onlyChatOrPhone: action.payload
		});
	case actionTypes.FINISH_INTERACTION_TASK:
		return finishInteractionTask(state, action);
	case actionTypes.FETCH_TAGS_BEGIN:
		return updateObject(state, { tagsStatus: setRequestStatus('begin') });
	case actionTypes.FETCH_TAGS_SUCCESS:
		return fetchInteractionTags(state, action);
	case actionTypes.FETCH_TAGS_FAILED:
		return updateObject(state, { tagsStatus: setRequestStatus('failed') });

	case actionTypes.FETCH_SUBTAGS_BEGIN:
		return updateObject(state, { subtagsStatus: setRequestStatus('begin') });
	case actionTypes.FETCH_SUBTAGS_SUCCESS:
		return fetchInteractionSubtags(state, action);
	case actionTypes.FETCH_SUBTAGS_FAILED:
		return updateObject(state, { subtagsStatus: setRequestStatus('failed') });

	case actionTypes.FETCH_ANSWERS_BEGIN:
		return updateObject(state, { answersStatus: setRequestStatus('begin') });
	case actionTypes.FETCH_ANSWERS_SUCCESS:
		return fetchInteractionAnswers(state, action);
	case actionTypes.FETCH_ANSWERS_FAILED:
		return updateObject(state, { answersStatus: setRequestStatus('failed') });
	default:
		return state;
	}
};

export default reducer;
